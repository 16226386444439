import { AfterAuthApi } from "..";
import { GET_NOTIFICATIONS, GET_NOTIFICATIONS_COUNT } from "../url";

export const getNotification = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_NOTIFICATIONS, "post")
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getNotificationCount = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_NOTIFICATIONS_COUNT, "post")
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
