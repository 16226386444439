import { getTaskCategory } from "../../helper/API/category";
import {
	GET_CATEGORY_LIST,
	CATEGORY_PROCESSING,
	STOP_CATEGORY_PROCESSING,
} from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setCategoryList = (payload) => {
	// console.log("payload ser", payload);
	return {
		type: GET_CATEGORY_LIST,
		payload,
	};
};

export const categoryList = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		// console.log("payload", payload);
		dispatch({ type: CATEGORY_PROCESSING });
		getTaskCategory()
			.then((category) => {
				// console.log("category", category);
				dispatch({
					type: GET_CATEGORY_LIST,
					payload: category.data,
				});
				resolve(category.data);
			})
			.catch((error) => {
				dispatch({ type: STOP_CATEGORY_PROCESSING });
				// console.log("error in category action", error.message);
			});
	});
};
