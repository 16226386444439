import { AfterAuthApi } from "..";
import {
	GET_CHART,
	GET_MONTH_DATA,
	GET_MONTH_DATA_CARD,
	DASHBOARD_PLAN_LIST,
	ADVOCATE_PLAN_LIST,
} from "../url";

export const getChartData = (submitData) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(GET_CHART, "post", submitData)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const getMonthChartData = () => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(GET_MONTH_DATA, "post")
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const getMonthChartDataCard = (submitData) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(GET_MONTH_DATA_CARD, "post", submitData)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const purchasedPlanList = () => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(DASHBOARD_PLAN_LIST, "post")
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const advocatePlanList = () => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(ADVOCATE_PLAN_LIST, "post")
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};
