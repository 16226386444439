import React from "react";
import { Button } from "@mui/material";
import TableComponent from "./TableComponent";
import { experimentalStyled as styled } from "@mui/material/styles";
import AddOfficeModal from "./AddOfficeModal";
import Loader from "../Loader";
import { getOffice } from "../../helper/API/office";
import RecordFound from "../RecordFound/RecordFound";
import { connect } from "react-redux";
import * as officeAction from "../../redux/officeList/action";
import SearchInput from "../SearchInput/index";

const TableTemplate = ({ officeListData, officeListFromRedux, processing }) => {
	const [tableRows, setTableRows] = React.useState(officeListFromRedux);
	const [loading, setLoading] = React.useState(false);
	const [refresh, setRefresh] = React.useState(1);
	const [addOffice, setAddOffice] = React.useState(false);
	const [searchVal, setSearchVal] = React.useState("");

	React.useEffect(() => {
		officeListData();
	}, []);

	React.useEffect(() => {
		setTableRows(officeListFromRedux);
	}, [officeListFromRedux]);

	const handleAddNote = (row) => {
		setAddOffice(true);
	};

	// SEARCH IN TABLE
	const onSearchOffice = () => {
		setSearchVal("");
		setTableRows(officeListFromRedux);
	};

	const onChangeSearch = (event) => {
		setSearchVal(event.target.value);
		if (event.target.value) {
			var updateState = tableRows.filter(({ label }) =>
				label.toLowerCase().includes(event.target.value.toLowerCase())
			);
			setTableRows(updateState);
			updateState = [];
			// console.log("obj if 1");
		} else if (event.target.value == "") {
			setTableRows(officeListFromRedux);
			// console.log("obj if 2");
		} else {
			setTableRows([]);
			// console.log("obj if 3");
		}
	};

	return (
		<div>
			<SearchInput
				onChange={onChangeSearch}
				searchVal={searchVal}
				onClick={onSearchOffice}
				placeholder='Escritório de busca'
			/>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					variant='contained'
					sx={{ marginBottom: 3 }}
					onClick={handleAddNote}>
					Adicionar tipo de escritório
				</Button>
			</div>

			{processing ? (
				<Loader />
			) : tableRows.length ? (
				<TableComponent
					rows={tableRows}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			) : (
				<RecordFound label='Nenhum registro foi encontrado' />
			)}

			{addOffice && (
				<AddOfficeModal
					open={addOffice}
					handleClose={() => setAddOffice(false)}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			)}
		</div>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		officeListData: () => dispatch(officeAction.officeList()),
	};
};
const mapStateToProp = (state) => {
	// console.log("state", state);
	return {
		officeListFromRedux: state?.officeListListReducer?.officeList,
		processing: state?.officeListListReducer?.processing,
	};
};

export default connect(mapStateToProp, mapDispatchToProp)(TableTemplate);
