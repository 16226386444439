import React from "react";
import Chart from "react-apexcharts";

const MonthPieChart = ({ chartData }) => {
	const series = [chartData?.grownInPr]; //70 percent
	const options = {
		// labels: [`${chartData?.grownInPr}%`], //label of this diagram
		chart: {
			width: "100%",
			height: "250px",
		},
		stroke: {
			lineCap: "round",
			// width: "25px",
		},
		colors: ["#F2697B"],
		plotOptions: {
			radialBar: {
				track: {
					margin: 0,
					padding: 10,
				},
				// hollow: {
				// 	margin: 0,
				// 	// size: "70%",
				// 	background: "#fff",
				// },
				dataLabels: {
					name: {
						fontSize: "22px",
					},
					value: {
						fontSize: "16px",
						color: "#111",
					},
					total: {
						color: "#fff",
						show: true,
						label: `${chartData?.grownInPr}%`,
						formatter: function (w) {
							// console.log("chart total value :: +", w);
							// By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
							return "";
						},
					},
				},
			},
		},
	};
	return (
		<>
			<Chart
				type='radialBar'
				series={series}
				options={options}
				width='100%'
				height={"250px"}
			/>
		</>
	);
};

export default MonthPieChart;
