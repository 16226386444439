import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import * as categoryAction from "../../redux/categoryList/action";
import { connect } from "react-redux";
import { deleteTaskCategoryColor } from "../../helper/API/category";

const DeleteModal = ({
	open,
	handleClose,
	data,
	setRefresh,
	refresh,
	adminListData,
	categoryListData,
}) => {
	const [loading, setLoading] = React.useState(false);
	// console.log("data", data);
	const handleSubmit = () => {
		// setLoading(true);
		const submitData = {
			id: data.id,
		};
		// console.log("submit data", submitData);
		deleteTaskCategoryColor(submitData).then((res) => {
			if (res.success) {
				handleClose();
				categoryListData();
				toast.success(res.message);
				setLoading(false);
			} else {
				toast.error(res.message);
				setLoading(false);
			}
		});
	};
	return (
		<div>
			<Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
				<DialogTitle>excluir categoria</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Tem certeza de que deseja excluir a categoria?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						variant='outlined'
						color='inherit'>
						Não
					</Button>
					<Button
						onClick={handleSubmit}
						variant='contained'
						color='error'
						sx={{ position: "relative" }}
						disabled={loading}>
						Sim
						{loading && (
							<CircularProgress
								size={24}
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		categoryListData: () => dispatch(categoryAction.categoryList()),
	};
};
export default connect(null, mapDispatchToProp)(DeleteModal);
