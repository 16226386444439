import { GET_PLAN_LIST, PLAN_PROCESSING, STOP_PLAN_PROCESSING } from "./type";

const defaultReducer = {
  planList: [],
  processing: false,
};

const planListListReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_PLAN_LIST:
      return {
        ...state,
        processing: false,

        planList: action.payload,
      };

    case PLAN_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case STOP_PLAN_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default planListListReducer;
