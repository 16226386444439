import * as React from "react";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import {
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	Switch,
	TextareaAutosize,
} from "@mui/material";
import { editPlans } from "../../helper/API/plan";
import { CircularProgress } from "@mui/material";
import * as planAction from "../../redux/planList/action";
import { connect } from "react-redux";

const EditPlanModal = ({
	open,
	handleClose,
	data,
	setRefresh,
	refresh,
	planListData,
}) => {
	function numberWithCommas(x) {
		return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
	}
	console.log("data", data);
	const [formValues, setFormValues] = React.useState({
		id: data.id,
		teamMembers: data.teamMembers,
		name: data.name,
		noOfProcess: data.noOfProcess,
		priceForMonth: data.priceForMonth,
		subtitle: data.subtitle,
	});
	const [loading, setLoading] = React.useState(false);
	const [planDays, setPlanDays] = React.useState(data.planDays);

	const [switchVal, setSwitchVal] = React.useState({
		chat: data.chat,
		clientMgt: data.clientMgt,
		customerAccess: data.customerAccess,
		financialMgt: data.financialMgt,
		isActive: data.isActive,
		processMgt: data.processMgt,
		teamMgt: data.teamMgt,
		officeApply: data.officeApply,
		isTrial: data.isTrial,
	});

	const handleFormChange = (e) => {
		setFormValues({
			...formValues,
			[e.target.name]: e.target.value,
		});
	};

	const handleSwitchChange = (e) => {
		// console.log("e.target.Checked", e.target.checked);
		setSwitchVal({
			...switchVal,
			[e.target.name]: e.target.checked,
		});
	};

	const handleSubmit = () => {
		setLoading(true);
		const submitData = {
			teamMembers: formValues.teamMembers,
			id: formValues.id,
			name: formValues.name,
			noOfProcess: formValues.noOfProcess,
			priceForMonth: formValues.priceForMonth,
			subtitle: formValues.subtitle,
			chat: switchVal.chat,
			clientMgt: switchVal.clientMgt,
			customerAccess: switchVal.customerAccess,
			financialMgt: switchVal.financialMgt,
			isActive: switchVal.isActive,
			processMgt: switchVal.processMgt,
			teamMgt: switchVal.teamMgt,
			officeApply: switchVal.officeApply,
			isTrial: switchVal.isTrial,
			...(switchVal.isTrial && { planDays: Number(planDays) }),
		};
		// console.log("submitData", submitData);
		editPlans(submitData).then((res) => {
			if (res.success) {
				handleClose();
				// setRefresh(refresh + 1);
				planListData();
				toast.success(res.message);
				setLoading(false);
			} else {
				toast.error(res.message);
				setLoading(false);
			}
		});
	};
	// console.log(
	// 	"formValues",
	// 	formValues.teamMembers
	// 		.toString()
	// 		?.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
	// );
	return (
		<div>
			<Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
				<DialogTitle>Editar modal do plano</DialogTitle>
				<DialogContent>
					{/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
					<Grid container spacing={2} gx={2}>
						<Grid item sm={6} md={6} lg={6}>
							<TextField
								autoFocus
								margin='dense'
								id='name'
								name='name'
								label='Nome'
								type='text'
								fullWidth
								variant='standard'
								defaultValue={formValues?.name}
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid item sm={6} md={6} lg={6}>
							<TextField
								autoFocus
								margin='dense'
								id='teamMembers'
								name='teamMembers'
								label='Membros do time'
								type='text'
								fullWidth
								variant='standard'
								defaultValue={formValues?.teamMembers}
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid item sm={6} md={6} lg={6}>
							<TextField
								autoFocus
								margin='dense'
								id='noOfProcess'
								name='noOfProcess'
								label='Nº de processo'
								type='text'
								fullWidth
								variant='standard'
								defaultValue={formValues?.noOfProcess}
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid item sm={6} md={6} lg={6}>
							<TextField
								autoFocus
								margin='dense'
								id='priceForMonth'
								name='priceForMonth'
								label='Preço por mês'
								type='text'
								fullWidth
								variant='standard'
								defaultValue={numberWithCommas(
									formValues?.priceForMonth
								)}
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid item sm={12} md={12} lg={12}>
							<TextareaAutosize
								name='subtitle'
								placeholder='legenda'
								minRows={5}
								variant='outlined'
								fullWidth
								defaultValue={formValues?.subtitle}
								onChange={handleFormChange}
								style={{
									fontSize: "15px",
									border: "2px solid #DBD7F4",
									// borderRadius: "13px",
									width: "100%",
									resize: "none",
								}}
							/>
						</Grid>
						{/* <Grid item sm={6} md={6} lg={6}>
							<FormGroup aria-label='position' row>
								<FormControlLabel
									control={
										<Switch
											name='isActive'
											color='primary'
											onChange={handleSwitchChange}
											defaultChecked={switchVal.isActive}
										/>
									}
									label='Ativa'
									labelPlacement='end'
								/>
							</FormGroup>
						</Grid> */}
						<Grid item sm={6} md={6} lg={6}>
							<FormGroup aria-label='position' row>
								<FormControlLabel
									control={
										<Switch
											name='clientMgt'
											color='primary'
											defaultChecked={switchVal.clientMgt}
											onChange={handleSwitchChange}
										/>
									}
									label='Gestão de clientes'
									labelPlacement='end'
								/>
							</FormGroup>
						</Grid>
						<Grid item sm={6} md={6} lg={6}>
							<FormGroup aria-label='position' row>
								<FormControlLabel
									control={
										<Switch
											name='customerAccess'
											defaultChecked={
												switchVal.customerAccess
											}
											color='primary'
											onChange={handleSwitchChange}
										/>
									}
									label='Acesso do cliente'
									labelPlacement='end'
								/>
							</FormGroup>
						</Grid>
						<Grid item sm={6} md={6} lg={6}>
							<FormGroup aria-label='position' row>
								<FormControlLabel
									control={
										<Switch
											name='financialMgt'
											defaultChecked={
												switchVal.financialMgt
											}
											color='primary'
											onChange={handleSwitchChange}
										/>
									}
									label='Gestão financeira'
									labelPlacement='end'
								/>
							</FormGroup>
						</Grid>
						<Grid item sm={6} md={6} lg={6}>
							<FormGroup aria-label='position' row>
								<FormControlLabel
									control={
										<Switch
											name='processMgt'
											defaultChecked={
												switchVal.processMgt
											}
											color='primary'
											onChange={handleSwitchChange}
										/>
									}
									label='Gerenciamento de processos'
									labelPlacement='end'
								/>
							</FormGroup>
						</Grid>
						<Grid item sm={6} md={6} lg={6}>
							<FormGroup aria-label='position' row>
								<FormControlLabel
									control={
										<Switch
											defaultChecked={switchVal.chat}
											name='chat'
											color='primary'
											onChange={handleSwitchChange}
										/>
									}
									label='Bater papo'
									labelPlacement='end'
								/>
							</FormGroup>
						</Grid>
						<Grid item sm={6} md={6} lg={6}>
							<FormGroup aria-label='position' row>
								<FormControlLabel
									control={
										<Switch
											defaultChecked={switchVal.teamMgt}
											name='teamMgt'
											color='primary'
											onChange={handleSwitchChange}
										/>
									}
									label='Gerenciamento de equipe'
									labelPlacement='end'
								/>
							</FormGroup>
						</Grid>
						<Grid item sm={6} md={6} lg={6}>
							<FormGroup aria-label='position' row>
								<FormControlLabel
									control={
										<Switch
											defaultChecked={
												switchVal.officeApply
											}
											name='officeApply'
											color='primary'
											onChange={handleSwitchChange}
										/>
									}
									label='Office'
									labelPlacement='end'
								/>
							</FormGroup>
						</Grid>

						<Grid item sm={6} md={6} lg={6}>
							<FormGroup aria-label='position' row>
								<FormControlLabel
									control={
										<Switch
											defaultChecked={switchVal.isTrial}
											name='isTrial'
											color='primary'
											onChange={handleSwitchChange}
										/>
									}
									label='is Trial'
									labelPlacement='end'
								/>
							</FormGroup>
						</Grid>
						{switchVal.isTrial && (
							<Grid item sm={12} md={12} lg={12}>
								<TextField
									margin='dense'
									id='name'
									name='planDays'
									label='dias do plano de teste'
									type='text'
									fullWidth
									variant='standard'
									defaultValue={planDays}
									onChange={(e) =>
										setPlanDays(e.target.value)
									}
								/>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					{/* <Button variant='outlined' onClick={handleClose}>
						Confirmar
					</Button> */}
					<Button
						onClick={handleSubmit}
						variant='contained'
						sx={{ position: "relative" }}
						disabled={loading}>
						Enviar
						{loading && (
							<CircularProgress
								size={24}
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		planListData: () => dispatch(planAction.planList()),
	};
};
export default connect(null, mapDispatchToProp)(EditPlanModal);
