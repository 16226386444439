import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import styled from "@emotion/styled";

const Img = styled("img")({
  // margin: 'auto',
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Error = () => {
  return (
    <Container
      component="main"
      maxWidth="md"
      sx={{ mt: 5, backgroundColor: "#fff" }}
    >
      <Box
        sx={{
          // marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Img
          alt="complex"
          src="/assets/images/inova_juris_logo.png"
          sx={{ height: 150 }}
        />
        <Box component="form" sx={{ mt: 1 }}>
          <h1>VOCÊ NÃO TEM ESSA PERMISSÃO</h1>
        </Box>
      </Box>
    </Container>
  );
};
export default Error;
