import React, { useState } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import {
	Card,
	CardContent,
	Grid,
	LinearProgress,
	Typography,
} from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import moment from "moment/moment";
import MonthPieChart from "../PieChartReact/MonthPieChart";
import { useEffect } from "react";
import {
	advocatePlanList,
	purchasedPlanList,
} from "../../helper/API/dashboard";
import Loader from "../Loader";

const Img = styled("img")({
	display: "block",
	maxWidth: "50px",
	maxHeight: "50px",
});
const ImgEllipse = styled("img")({
	maxWidth: "100px",
	maxHeight: "100px",
});
const clientes = [
	{
		id: 1,
		name: "Marcos S.",
		email: "email@email.com",
		phone: "11-0000-0000",
		uid: "123.456.678-10",
	},
	{
		id: 2,
		name: "Marcos S.2",
		email: "email2@email.com",
		phone: "22-0000-0000",
		uid: "222.456.678-10",
	},
	{
		id: 3,
		name: "Marcos S.2",
		email: "email2@email.com",
		phone: "22-0000-0000",
		uid: "222.456.678-10",
	},
];

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		borderRadius: 160,
		marginBottom: "3rem",
		"& .MuiTab-textColorSecondary.Mui-selected": {
			color: "#ffff",
			zIndex: 60,
		},
		"& .MuiTabs-indicator": {
			height: "100% !important",
			borderRadius: 160,
			background:
				"linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
		},
		"& .MuiTab-wrapper": {
			textTransform: "capitalize",
			fontSize: 16,
			fontWeight: "bold",
		},
		"& .MuiLinearProgress-root": {
			height: "100px",
		},
		"& .MuiPaper-elevation1": {
			boxShadow: "unset !important",
		},
	},
	actionContainer: {
		background: "#D6D9FF",
		borderTopRightRadius: "13px",
		borderBottomRightRadius: "13px",
	},
	title: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: "800",
		fontSize: "20px",
	},
	subtitle: {
		color: "#757575",
		fontFamily: "Poppins",
		fontWeight: 400,
		fontSize: 18,
	},
	card: {
		boxShadow: "unset !important",
		borderRadius: 11,
	},
	opinion: {
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "20px",
		lineHeight: "22px",
	},
	card2: {
		maxWidth: "20vw",
		height: "50vh",
		background: "url(/assets/images/card2.png) center center",
		backgroundPosition: "contain",
		backgroundRepeat: "no-repeat",
		backgroundSize: "50vh",
		position: "relative",
	},
	wrap: {
		width: "200px",
		height: "auto",
		margin: "auto",
		textAlign: "center",
		position: "relative",
	},
	text: {
		position: "absolute",
		top: "43%",
		left: "43%",
		color: "#ffffff",
	},

	rootProgress: {
		flexGrow: 1,
		marginBottom: "1rem",
		marginTop: "2rem",
		padding: "0rem 1rem",
		"& .MuiLinearProgress-bar": {
			borderRadius: "18px",
		},
	},
	progressLabel: {
		position: "absolute",
		width: "100%",
		height: "85%",
		zIndex: 1,
		// maxHeight: "75px", // borderlinearprogress root.height
		textAlign: "center",
		display: "flex",
		alignItems: "center",
		"& span": {
			width: "100%",
			color: "#fff",
			fontWeight: 600,
		},
	},
	wrapText: {
		display: "-webkit-box",
		maxWidth: "400px",
		lineClamp: 1,
		overflow: "hidden",
		textOverflow: "ellipsis",
		"-webkitBoxOrient": "vertical",
	},
});

const ProgressCard = ({ chartData }) => {
	const classes = useStyles();
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [advocate, setAdvocate] = useState([]);
	//   const ab = moment().format();
	var ab = moment(new Date()).locale("pt-br").format("MMMM");

	useEffect(() => {
		setLoading(true);
		purchasedPlanList().then((res) => {
			// console.log("res", res);
			if (res.success) {
				setLoading(false);
				setData(res.data);
			} else {
				setLoading(false);
			}
		});

		advocatePlanList().then((res) => {
			// console.log("res setAdvocate", res);
			if (res.success) {
				setLoading(false);
				setAdvocate(res.data);
			} else {
				setLoading(false);
			}
		});
	}, []);

	// console.log("chartData", chartData);

	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<Grid container>
					<Grid item sm={12} md={3} lg={3}>
						<Card
							sx={{
								maxWidth: "20vw",
								height: "50vh",
								background: "#F26D81",
							}}>
							<CardContent
								sx={{
									background: "#F26D81",
									overflow: "hidden",
									height: "100%",
									width: "100%",
								}}>
								<Typography
									variant='h6'
									sx={{ color: "#ffffff", fontWeight: 600 }}>
									{" "}
									Tipo de Assinatura
								</Typography>
								<div
									mx={4}
									style={{
										overflowY: "auto",
										height: "40vh",
										width: "90%",
									}}>
									{data?.map((obj, i) => (
										<div className={classes.rootProgress}>
											<Grid
												container
												spacing={1}
												justify='space-between'
												sx={{ position: "relative" }}>
												<Grid
													item
													xs={12}
													md={12}
													spacing={0}>
													<div
														className={
															classes.progressLabel
														}>
														<span
															className={
																classes.wrapText
															}>
															{obj.plan}
														</span>
														<span>
															{
																obj.totalPurchasePlan
															}
															%
														</span>
													</div>
													<LinearProgress
														variant='determinate'
														style={{
															height: "40px",
															borderRadius:
																"18px",
														}}
														value={
															obj.totalPurchasePlan
														}
													/>
												</Grid>
											</Grid>
										</div>
									))}
								</div>
							</CardContent>
						</Card>
					</Grid>

					{chartData?.currentMonth && (
						<Grid item sm={12} md={3} lg={3}>
							<Box>
								<Card className={classes.card2}>
									<Typography
										variant='h6'
										sx={{
											color: "#ffffff",
											textAlign: "center",
											paddingTop: 2,
											fontWeight: 700,
										}}>
										Assinaturas {chartData?.currentMonth}
									</Typography>

									<Typography
										variant='h6'
										sx={{
											color: "rgba(255, 255, 255, 0.56)",
											textAlign: "center",
										}}>
										{/* Junho de 2022 */}
										{`${chartData?.currentMonth} ${chartData?.currentYear}`}
									</Typography>

									<div className={classes.wrap}>
										{/* <img
                  src="/assets/images/Ellipse1.png"
                  width="150"
                  height="150"
                />
                <div className={classes.text}>{chartData?.grownInPr}%</div> */}
										{/* {chartData?.grownInPr && ( */}
										<MonthPieChart chartData={chartData} />
										{/* )} */}
									</div>

									<div style={{ marginTop: "1rem" }}>
										<Typography
											variant='body1'
											sx={{
												color: "#FFFFFF",
												fontWeight: 500,
												textAlign: "center",
												marginTop: "5vh",
											}}>
											{chartData?.growth ===
											"Aumento no mês Infinity"
												? "100"
												: chartData?.growth}
											%
										</Typography>
									</div>

									{/* <Typography
                                variant='h6'
                                sx={{
                                    color: "rgba(255, 255, 255, 0.56)",
                                    textAlign: "center",
                                }}>
                                Junho de 2022
                            </Typography>
                            <Typography
                                variant='body1'
                                mt={20}
                                sx={{
                                    color: "rgba(255, 255, 255, 0.56)",
                                    textAlign: "center",
                                }}>
                                Parabéns, suas assinaturas cresceram 38%
                            </Typography> */}
								</Card>
							</Box>
						</Grid>
					)}
					<Grid item sm={12} md={6} lg={6}>
						{advocate.length && (
							<Typography px={2} variant='h6' fontWeight={600}>
								Últimas assinaturas
							</Typography>
						)}
						{advocate?.map((obj) => (
							<Box
								ml={2}
								mb={2}
								display={"flex"}
								justifyContent={"space-between"}
								alignItems={"center"}
								sx={{
									background: "#FFF",
									padding: "1% 3%",
									borderRadius: "10px",
									boxShadow:
										"13.0734px 31.3761px 70.5963px rgba(0, 0, 0, 0.06)",
								}}>
								<Box
									sx={{
										border: "8px solid #734699",
										borderRadius: "50%",
										padding: "6px",
									}}>
									<Img
										alt='profile'
										src={obj.avatar}
										sx={{
											borderRadius: "50%",
											height: "60px",
											width: "60px",
										}}
									/>
								</Box>
								<Box flex={1} px={2}>
									<p
										style={{
											margin: "0",
											color: "#434343 !important",
											fontWeight: 500,
											fontSize: 18,
										}}>
										{obj?.name}
									</p>
									<p className={classes.subtitle}>
										{obj?.registeredType}
									</p>
								</Box>
								<Box
									p={0}
									color={"#544784"}
									sx={{ fontWeight: 500 }}>
									{obj?.month}
								</Box>
							</Box>
						))}
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export default ProgressCard;
