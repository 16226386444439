import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {
	Box,
	Card,
	CardContent,
	FormControl,
	Typography,
	InputLabel,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
	Checkbox,
	Grid,
} from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { editOccupationArea } from "../../helper/API/occupation";
import { toast } from "react-toastify";
import { editNoteType } from "../../helper/API/note";
import { CircularProgress } from "@mui/material";
import { editAdmin, getPermissionList } from "../../helper/API/permission";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { experimentalStyled as styled } from "@mui/material/styles";
import * as adminAction from "../../redux/adminList/action";
import { connect } from "react-redux";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 15,
		position: "relative",
		color: "#171B1E",
		backgroundColor: "##FFFFFF",
		border: "2px solid #D7D7D7",
		fontSize: 16,
		width: "100%",
		padding: "15px 18px",
		transition: theme.transitions.create([
			"border-color",
			"background-color",
			"box-shadow",
		]),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			boxShadow: `${alpha(
				theme.palette.primary.main,
				0.25
			)} 0 0 0 0.2rem`,
			borderColor: theme.palette.primary.main,
		},
	},
}));

const EditAdminModal = ({
	open,
	handleClose,
	data,
	setRefresh,
	refresh,
	classes,
	adminListData,
}) => {
	const [loading, setLoading] = React.useState(false);
	const [permissionList, setPermissionList] = useState([]);
	const [permissions, setPermissions] = useState(data.permission);
	const [name, setName] = useState(data.name);

	useEffect(() => {
		getPermissionList().then((res) => {
			// console.log("permission list", res);
			if (res.success) {
				setPermissionList(res.data);
			}
		});
	}, []);

	const handleChecke = (e, data, index) => {
		// setPermissions([...permissions, data]);
		// console.log("data", data);

		const permissionArr = permissions.indexOf(data);
		const permissionId = permissions.find((obj) => obj.id == data.id);
		// console.log("permissionId", permissionId);
		if (!permissionId) {
			setPermissions([...permissions, data]);
		} else {
			const permissionId = permissions.filter(
				(obj) => obj.id !== data.id
			);

			setPermissions(permissionId);
		}
	};

	const handleSubmit = () => {
		setLoading(true);
		const submitData = {
			id: data.id,
			name: name,
			permission: permissions,
		};

		editAdmin(submitData).then((res) => {
			if (res.success) {
				handleClose();
				// setRefresh(refresh + 1);
				adminListData();
				toast.success(res.message);
				setLoading(false);
			} else {
				toast.error(res.message);
				setLoading(false);
			}
		});
		// console.log("submitData", submitData);
	};
	const inviteId = permissions.map((obj, index) => obj.id);
	// console.log("permissions", permissions);
	return (
		<div>
			<Dialog fullWidth maxWidth={"md"} open={open} onClose={handleClose}>
				<DialogTitle>Editar tipo de nota</DialogTitle>
				<DialogContent>
					{/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
					<Grid container sx={{ mt: 3 }}>
						<Grid item md={12}>
							<TextField
								name='name'
								variant='outlined'
								fullWidth
								defaultValue={name}
								onChange={(e) => setName(e.target.value)}
								label='Nome'
							/>
						</Grid>
					</Grid>

					<Box
						sx={{
							width: "100%",

							bgcolor: "background.paper",
						}}>
						<nav
							aria-label='main mailbox folders'
							style={{ padding: "0.3rem 3rem" }}>
							<List>
								<ListItem disablePadding>
									{/* <ListItemButton> */}
									<ListItemText
										className={classes.bold}
										primary='Permissões'
									/>
									{/* </ListItemButton> */}
								</ListItem>
							</List>
						</nav>
						<Divider />
						{permissionList?.map((obj, indx) => (
							<Box
								sx={{
									display: "flex",
									justifyContent: "start",
									alignItems: "center",
									mb: 2,
									padding: "0.3rem 3rem",
								}}
								key={indx}>
								<Checkbox
									defaultChecked={
										inviteId.indexOf(obj.id) >= 0
											? true
											: false
									}
									onChange={(e) => handleChecke(e, obj, indx)}
								/>
								<Typography sx={{ ml: 1 }}>
									{obj.label}
								</Typography>
							</Box>
						))}
					</Box>
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' onClick={handleClose}>
						Perto
					</Button>
					<Button
						onClick={handleSubmit}
						variant='contained'
						sx={{ position: "relative" }}
						disabled={loading}>
						Enviar
						{loading && (
							<CircularProgress
								size={24}
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
const mapDispatchToProp = (dispatch) => {
	return {
		adminListData: () => dispatch(adminAction.adminList()),
	};
};
export default connect(null, mapDispatchToProp)(EditAdminModal);
