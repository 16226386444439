// import React from "react";
// import Box from "@mui/material/Box";
// import { makeStyles } from "@material-ui/core/styles";
// import Tabs from "@material-ui/core/Tabs";
// import Tab from "@material-ui/core/Tab";
// import { experimentalStyled as styled } from "@mui/material/styles";
// import {  Card, CardContent, Grid, TextField, Typography } from "@mui/material";
// import StyledTextField from "../components/Inputs/StyledTextField";
// import { useNavigate } from "react-router-dom";
// import DadosAssinatura from "../components/DadosAssinatura";
// import AdvogadosProfile from "../components/AdvogadosProfile";

// const Img = styled("img")({
//     display: "block",
// });

// const useStyles = makeStyles({
//     root: {
//         flexGrow: 1,
//         borderRadius: 10,
//         marginBottom: "1rem",

//         "& .MuiGrid-root": {
//             "& .MuiFormControl-root": {
//                 "& .MuiFormLabel-root": {
//                     fontFamily: 'Poppins',
//                     fontStyle: "normal",
//                     fontWeight: 600,
//                     fontSize: "16px",
//                     lineHeight: "24px",
//                     color: "#333333",

//                 }
//             }
//         },

//         "& .MuiTab-textColorSecondary.Mui-selected": {
//             color: "#ffff",
//             zIndex: 60,
//         },
//         "& .MuiTabs-indicator": {
//             height: "100% !important",
//             borderRadius: 160,

//             background: "linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
//         },
//         "& .MuiTab-wrapper": {
//             textTransform: "capitalize",
//             fontSize: 16,
//         },
//         "& .Mui-selected": {
//             color: "#000000 !important",
//             fontSize: 16,
//             fontWeight: "bold",
//         },

//         "& .MuiInputBase-root": {
//             padding: "5px 5px !important",
//             borderRadius: "13px",
//         },
//         "& .MuiPaper-elevation": {
//             boxShadow: "unset !important",
//         },
//         "&::placeholder": {
//             textOverflow: "ellipsis !important",
//             color: "#000",
//         },
//         "& .MuiFormLabel-root": {
//             color: "#000",

//         },
//     },
//     input: {
//         "&::placeholder": {
//             textOverflow: "ellipsis !important",
//             color: "#000",
//         },
//     },
//     actionContainer: {
//         background: "#D6D9FF",
//         borderTopRightRadius: "13px",
//         borderBottomRightRadius: "13px",
//     },
//     title: {
//         fontFamily: "Poppins",
//         color: "#FFFFFF",
//     },
//     subtitle: {
//         fontFamily: "Poppins",
//         color: "#FFFFFF",

//     },

//     card: {
//         boxShadow: "unset !important",
//         borderRadius: 11,
//     },

// });

// const Office = () => {
//     const classes = useStyles();
//     const [value, setValue] = React.useState(0);
//     const handleChange = (event, newValue) => {
//         setValue(newValue);
//     };

//     return (
//         <div>
//             <Box className={classes.root} sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}

//             >
//                 <Box sx={{ width: '100%', }}>
//                     <Tabs
//                         TabIndicatorProps={{ style: { display: "none" } }}
//                         onChange={handleChange}
//                         value={value}
//                         aria-label="Tabs where selection follows focus"
//                         sx={{ border: "1px solid #E1E1FB" }}
//                     >
//                         <Tab label="Escritório" sx={{ fontFamily: "Poppins", color: "red" }} />
//                         <Tab label="Dados da assinatura" sx={{ fontFamily: "Poppins", fontWeight: 400 }} />
//                         <Tab label="Visualizar" sx={{ fontFamily: "Poppins", fontWeight: 400 }} />

//                     </Tabs>
//                 </Box>
//                 <hr />
//                 {value === 0 &&
//                     <Grid container spacing={{ xs: 2, md: 3 }} className={classes.root}>

//                         <Grid item xs={12} sm={12} md={12}>

//                             <Card variant="elevation" sx={{ cursor: "pointer" }}>
//                                 <CardContent>
//                                     <Card variant="elevation" className={classes.card} >
//                                         <Box>
//                                             <Typography variant="h5">Dados do escritório</Typography>
//                                         </Box>
//                                         <Grid container spacing={4} mt={1}>
//                                             <Grid item md={6}>
//                                                 <StyledTextField label="Nome" placeholder='Escritório Juris' />

//                                             </Grid>
//                                             <Grid item md={6}>

//                                                 <StyledTextField label="Razão Social" placeholder='Escritório Juris LTDA.' />

//                                             </Grid>

//                                             <Grid item md={6}>
//                                                 <StyledTextField label="CNPJ/CPF" placeholder="000.000.0000.00" />

//                                             </Grid>
//                                             <Grid item md={6}>
//                                                 <StyledTextField label="Telefone" placeholder='00- 0000-0000' />
//                                             </Grid>
//                                             <Grid item md={6}>
//                                                 <StyledTextField label="Email" placeholder='email@email.com' />
//                                             </Grid>

//                                             <Grid item md={6}>
//                                                 <StyledTextField label="Área de atuação" placeholder='Empresarial' />
//                                             </Grid>
//                                             <Grid item md={12}>
//                                                 <StyledTextField label="Website" placeholder='www.site.com.br' />
//                                             </Grid>
//                                             <Grid item md={6}>
//                                                 <StyledTextField label="Endereço" placeholder='19920-09902' />
//                                             </Grid>
//                                             <Grid item md={6}>
//                                                 <StyledTextField label="Estado/Cidade" placeholder='São Paulo, SP' />
//                                             </Grid>
//                                             <Grid item md={12}>
//                                                 <StyledTextField label="Rua" placeholder='Rua juris' />
//                                             </Grid>
//                                             <Grid item md={6}>
//                                                 <StyledTextField label="Bairro" placeholder='juris' />
//                                             </Grid>
//                                             <Grid item md={6}>
//                                                 <StyledTextField label="Sala" placeholder='300' />
//                                             </Grid>

//                                             <Grid item md={12}>
//                                                 <TextField fullWidth
//                                                     label="Descrição do escritório"
//                                                     placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. "
//                                                     id="fullWidth" />

//                                             </Grid>
//                                         </Grid>
//                                     </Card>

//                                 </CardContent>
//                             </Card>
//                         </Grid>
//                     </Grid>
//                 }
//                 {value === 1 &&
//                     <>
//                         <DadosAssinatura />
//                     </>
//                 }
//                 {value === 2 &&
//                     <>
//                         <AdvogadosProfile />
//                     </>
//                 }
//             </Box>
//         </div>
//     )
// }

// export default Office

import React from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";

import CustomizedSearchBar from "../components/Inputs/SearchBar";
import TableTemplate from "../components/office/TableTemplate";

const Img = styled("img")({
  // margin: 'auto',
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});

const Office = () => {
  return (
    <div>
      <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        {/* <CustomizedSearchBar /> */}
        <TableTemplate />
      </Box>
    </div>
  );
};

export default Office;
