import * as React from "react";
// import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { editOccupationArea } from "../../helper/API/occupation";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import * as occupationAction from "../../redux/occupationList/action";

const EditOccupationModal = ({
	open,
	handleClose,
	data,
	setRefresh,
	refresh,
	occupationListData,
}) => {
	const [label, setLabel] = React.useState(data.label);
	const [loading, setLoading] = React.useState(false);

	const handleSubmit = () => {
		setLoading(true);
		const submitData = {
			id: data.id,
			label,
		};

		editOccupationArea(submitData).then((res) => {
			if (res.success) {
				handleClose();
				// setRefresh(refresh + 1);
				occupationListData();
				toast.success(res.message);
				setLoading(false);
			} else {
				toast.error(res.message);
				setLoading(false);
			}
		});
		// console.log("submitData", submitData);
	};

	return (
		<div>
			<Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
				<DialogTitle>Editar ocupação</DialogTitle>
				<DialogContent>
					{/* <DialogContentText>
            You can set my maximum width and whether to adapt or not.
          </DialogContentText> */}
					<TextField
						autoFocus
						margin='dense'
						id='name'
						label='Etiqueta'
						type='text'
						fullWidth
						variant='standard'
						defaultValue={data?.label}
						onChange={(e) => setLabel(e.target.value)}
					/>
					{/* </FormControl> */}
					{/* <FormControlLabel
            sx={{ mt: 1 }}
            label="Active"
            control={<Switch checked={active} onChange={handleActiveChange} />}
          /> */}
					{/* </Box> */}
				</DialogContent>
				<DialogActions>
					<Button variant='outlined' onClick={handleClose}>
						Perto
					</Button>
					<Button
						onClick={handleSubmit}
						variant='contained'
						sx={{ position: "relative" }}
						disabled={loading}>
						Enviar
						{loading && (
							<CircularProgress
								size={24}
								sx={{
									position: "absolute",
									top: "50%",
									left: "50%",
									marginTop: "-12px",
									marginLeft: "-12px",
								}}
							/>
						)}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		occupationListData: () => dispatch(occupationAction.occupationList()),
	};
};
export default connect(null, mapDispatchToProp)(EditOccupationModal);
