import { AfterAuthApi } from "..";
import {
  ADD_NOTE_TYPE,
  DELETE_NOTE_TYPE,
  GET_NOTE_TYPE,
  EDIT_NOTE_TYPE,
  ACTIVE_NOTE_TYPE,
} from "../url";

export const addNoteType = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_NOTE_TYPE, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getNoteType = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_NOTE_TYPE, "post")
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const deleteNoteType = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_NOTE_TYPE, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const editNoteType = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(EDIT_NOTE_TYPE, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const activeNoteType = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ACTIVE_NOTE_TYPE, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
