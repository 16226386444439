import { combineReducers } from "redux";
import loginUsers from "./login";
import planListListReducer from "./planList/index";
import noteListListReducer from "./noteList/index";
import occupationListListReducer from "./occupationList/index";
import officeListListReducer from "./officeList/index";
import clientListReducer from "./clientList/index";
import adminListReducer from "./adminList/index";
import subscriberListReducer from "./subscriberlist/index";
import categoryListReducer from "./categoryList/index";

const rootReducer = combineReducers({
	loginUsers,
	planListListReducer,
	noteListListReducer,
	occupationListListReducer,
	officeListListReducer,
	clientListReducer,
	adminListReducer,
	subscriberListReducer,
	categoryListReducer,
});

export default rootReducer;
