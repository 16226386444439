// AUTH URL
export const LOGIN_USER = "admin-auth/login";
export const FORGOT_PASSWORD = "admin-auth/forgot-password";
export const VERIFY_FORGOT_PASSWORD = "admin-auth/verify-forgot-pin";

// AFTER AUTH URL
export const ADD_OCCUPATION_AREA = "admin/occupation/add";
export const GET_OCCUPATION_AREA = "admin/occupation/list";
export const EDIT_OCCUPATION_AREA = "admin/occupation/edit";
export const DELETE_OCCUPATION_AREA = "admin/occupation/delete";
export const ACTIVE_OCCUPATION = "admin/occupation/active-Occupation-area";

// NOTE
export const GET_NOTE_TYPE = "admin/notes/get";
export const ADD_NOTE_TYPE = "admin/notes/add";
export const EDIT_NOTE_TYPE = "admin/notes/edit";
export const DELETE_NOTE_TYPE = "admin/notes/delete";
export const ACTIVE_NOTE_TYPE = "admin/notes/active-note-type";

// OFFICE
export const ADD_OFFICE = "admin/office/add";
export const DELETE_OFFICE = "admin/office/delete";
export const GET_OFFICE = "admin/office/get";
export const EDIT_OFFICE = "admin/office/edit";

// OFFICE
export const ADD_PLAN = "admin/plans/add";
export const DELETE_PLAN = "admin/notes/delete";
export const GET_PLAN = "admin/plans/list";
export const EDIT_PLAN = "admin/plans/edit";
export const DEFUALT_PLAN = "admin/plans/set-default";
export const ACTIVE_OFFICE = "admin/office/active-office-type";
export const TRIAL_PLAN = "admin/plans/set-trial-plan";

// PERMISSIONS
export const GET_PERMISSION = "permission/get-permission-list";
export const ADD_ADMIN = "admin/add/add-admin";
export const GET_ADMIN = "admin/add/get-admin";
export const DELETE_ADMIN = "admin/add/delete-admin";
export const EDIT_ADMIN = "admin/add/edit-admin";

// SUBSCRIBERS
export const GET_SUBSCRIBERS = "admin/subscriber/get-subscriber-list";
export const GET_SUBSCRIBER_OFFICE = "admin/subscriber/get-subscriber-office";
export const GET_SUBSCRIBER_DETAIL = "admin/subscriber/get-subscriber-details";
export const GET_CLIENTS = "admin/client/get-user-client";

// NOTIFICATIONS
export const GET_NOTIFICATIONS = "admin/dashboard/get-notification-list";
export const GET_NOTIFICATIONS_COUNT = "admin/dashboard/get-notification-count";

// DASHBOARD
export const GET_CHART = "admin/dashboard/dashboard";
export const GET_MONTH_DATA = "admin/dashboard/get-current-month-data";
export const GET_MONTH_DATA_CARD = "admin/dashboard/get-Statistics-data";

export const GET_CATEGORY = "admin/task/get-category";
export const GET_CATEGORY_COLOR = "public/get-category-color";

export const ADD_CATEGORY = "admin/task/add-category";
export const DELETE_CATEGORY = "admin/task/delete-category";
export const EDIT_CATEGORY = "admin/task/edit-category";

export const DASHBOARD_PLAN_LIST = "admin/dashboard/purchase-plan-list";
export const ADVOCATE_PLAN_LIST = "admin/dashboard/advocate-plan-list";
