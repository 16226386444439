import {
  GET_SUBSCRIBER_LIST,
  SUBSCRIBER_PROCESSING,
  STOP_SUBSCRIBER_PROCESSING,
} from "./type";

const defaultReducer = {
  subscriberList: [],
  processing: false,
};

const subscriberListReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_SUBSCRIBER_LIST:
      return {
        ...state,
        processing: false,

        subscriberList: action.payload,
      };

    case SUBSCRIBER_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case STOP_SUBSCRIBER_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default subscriberListReducer;
