import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TableComponent from "./TableComponent";
import { experimentalStyled as styled } from "@mui/material/styles";

const Img = styled("img")({
	display: "block",
});

const TableTemplate = ({ rows }) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectVal, setSelectVal] = React.useState("clientes");
	const open = Boolean(anchorEl);

	const [tableRows, setTableRows] = React.useState(rows);

	React.useEffect(() => {
		setTableRows(rows);
	}, [rows]);

	const handleClick = (event) => {
		// console.log("event.currentTarget", event.currentTarget);
		setAnchorEl(event.currentTarget);
	};

	// console.log("selectVal", selectVal);

	return (
		<div>
			<Container
				id='basic-button'
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup='true'
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				sx={{
					paddingLeft: "unset !important",
					cursor: "pointer",
					width: 282,
					marginLeft: "unset !important",
					position: "relative",
				}}>
				{/* <Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}>
					<Typography sx={{ color: "#464A53", fontWeight: "600" }} variant='h6'>
						Ranking Advogados
					</Typography>
					<KeyboardArrowDownIcon />
				</Box> */}
			</Container>

			<TableComponent rows={tableRows} />
		</div>
	);
};

export default TableTemplate;
