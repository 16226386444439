import {
  GET_OFFICE_LIST,
  OFFICE_PROCESSING,
  STOP_OFFICE_PROCESSING,
} from "./type";

const defaultReducer = {
  officeList: [],
  processing: false,
};

const officeListListReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_OFFICE_LIST:
      return {
        ...state,
        processing: false,

        officeList: action.payload,
      };

    case OFFICE_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case STOP_OFFICE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default officeListListReducer;
