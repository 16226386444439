import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import StyledButton from "../components/StyledButton";
import styled from "@emotion/styled";
import { verifyForgotPassword } from "../helper/API/auth";
import { toast } from "react-toastify";
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
const Img = styled("img")({
	// margin: 'auto',
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
	position: "relative",
	left: "-10px",
	top: "-20px",
});

const VerifyAndUpdatePassword = () => {
	// const { login } = useAuth();
	// const { t } = useTranslation();
	const [showPassword, setShowPassword] = React.useState(false);

	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const confirmPassword = data.get("confirmPassword");
		const submitData = {
			email: JSON.parse(localStorage.getItem("email")),
			forgotPin: data.get("forgotOtp"),
			password: data.get("password"),
		};
		if (
			submitData.email &&
			submitData.forgotPin &&
			submitData.password &&
			!confirmPassword
		) {
			toast.error("Please enter confirm password");
		} else if (submitData.password !== confirmPassword) {
			toast.error("Password not match");
		} else {
			// console.log("submitData", submitData);
			verifyForgotPassword(submitData).then((res) => {
				if (res.success) {
					toast.success(res.message);
					navigate("/");
				} else {
					toast.error(res.message);
				}
			});
		}
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<>
			<Container
				component='main'
				maxWidth='xs'
				sx={{ mt: 5, py: 5, px: 5, backgroundColor: "#fff" }}>
				<Link to='/forgot-password'>
					<Img
						alt='complex'
						src='/assets/images/backbtn.png'
						sx={{ height: 150 }}
					/>
				</Link>
				<Container>
					<Box
						sx={{
							marginTop: 3,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Typography component='h2' variant='h5' sx={{ mb: 3 }}>
							Esqueceu sua senha
						</Typography>
						<Typography
							component='h6'
							variant='h5'
							sx={{
								color: "#92929D",
								fontSize: "16px",
								textAlign: "center",
								mb: 3,
							}}>
							Digite o endereço de e-mail que você usou quando
							entrou para redefinir sua senha.
						</Typography>
						<Box
							component='form'
							onSubmit={handleSubmit}
							noValidate
							sx={{ mt: 1 }}>
							<TextField
								margin='normal'
								placeholder='Digite seu OTP'
								required
								fullWidth
								id='otp'
								label='OTP'
								name='forgotOtp'
								autoComplete='otp'
								autoFocus
							/>
							<TextField
								margin='normal'
								placeholder='Digite sua senha'
								required
								fullWidth
								id='password'
								label='Senha'
								name='password'
								autoComplete='password'
							/>
							<FormControl
								sx={{ mt: 1 }}
								variant='outlined'
								fullWidth>
								<InputLabel htmlFor='confirmPassword'>
									Confirme a Senha
								</InputLabel>
								<OutlinedInput
									placeholder='Digite sua senha de confirmação'
									id='confirmPassword'
									label='Confirme a Senha'
									name='confirmPassword'
									autoComplete='confirmPassword'
									required
									fullWidth
									type={showPassword ? "text" : "password"}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												aria-label='toggle password visibility'
												onClick={
													handleClickShowPassword
												}
												edge='end'>
												{showPassword ? (
													<VisibilityOff />
												) : (
													<Visibility />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
							<StyledButton
								type={"submit"}
								fullWidth
								variant={"contained"}
								btnText={"Recuperar senha"}
								sx={{ mt: 3, mb: 2 }}
							/>
						</Box>
					</Box>
				</Container>
			</Container>
		</>
	);
};

export default VerifyAndUpdatePassword;
