import React from "react";
import { Button } from "@mui/material";
import TableComponent from "./TableComponent";
import { experimentalStyled as styled } from "@mui/material/styles";
import AddPlanModal from "./AddPlanModal";
import Loader from "../Loader";
import { getOffice } from "../../helper/API/office";
import { getPlan } from "../../helper/API/plan";
import RecordFound from "../RecordFound/RecordFound";
import SearchInput from "../SearchInput/index";
import { connect } from "react-redux";
import * as planAction from "../../redux/planList/action";
const Img = styled("img")({
	display: "block",
});

const TableTemplate = ({ planListData, planListFromRedux, processing }) => {
	const [tableRows, setTableRows] = React.useState(planListFromRedux);
	// const [loading, setLoading] = React.useState(processing);
	const [refresh, setRefresh] = React.useState(1);
	const [addOffice, setAddOffice] = React.useState(false);
	const [searchVal, setSearchVal] = React.useState("");

	React.useEffect(() => {
		planListData();
	}, [refresh]);
	React.useEffect(() => {
		setTableRows(planListFromRedux);
	}, [planListFromRedux]);

	const handleAddNote = (row) => {
		setAddOffice(true);
	};

	// SEARCH IN TABLE
	const onSearchPlan = () => {
		setSearchVal("");
		setTableRows(planListFromRedux);
	};

	const onChangeSearch = (event) => {
		setSearchVal(event.target.value);
		if (event.target.value) {
			var updateState = tableRows.filter(({ name }) =>
				name.toLowerCase().includes(event.target.value.toLowerCase())
			);
			setTableRows(updateState);
			updateState = [];
			// console.log("obj if 1");
		} else if (event.target.value == "") {
			setTableRows(planListFromRedux);
			// console.log("obj if 2");
		} else {
			setTableRows([]);
			// console.log("obj if 3");
		}
	};

	return (
		<div>
			<SearchInput
				onChange={onChangeSearch}
				searchVal={searchVal}
				onClick={onSearchPlan}
				placeholder='Buscar Planos'
			/>
			{/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					variant='contained'
					sx={{ marginBottom: 3 }}
					onClick={handleAddNote}>
					Adicionar plano
				</Button>
			</div> */}

			{processing ? (
				<Loader />
			) : tableRows.length ? (
				<TableComponent
					rows={tableRows}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			) : (
				<RecordFound label='Nenhum registro foi encontrado' />
			)}

			{addOffice && (
				<AddPlanModal
					open={addOffice}
					handleClose={() => setAddOffice(false)}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			)}
		</div>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		planListData: () => dispatch(planAction.planList()),
	};
};
const mapStateToProp = (state) => {
	// console.log("state", state);
	return {
		planListFromRedux: state?.planListListReducer?.planList,
		processing: state?.planListListReducer?.processing,
	};
};

export default connect(mapStateToProp, mapDispatchToProp)(TableTemplate);
