import React from "react";
import Chart from "react-apexcharts";

const PieChartReact = ({ chartData }) => {
	// var data = {
	// 	options: {
	// 		series: [44, 55, 67, 83],
	// 		chart: {
	// 			height: 350,
	// 			type: "radialBar",
	// 		},
	// 		plotOptions: {
	// 			radialBar: {
	// 				dataLabels: {
	// 					name: {
	// 						fontSize: "22px",
	// 					},
	// 					value: {
	// 						fontSize: "16px",
	// 					},
	// 					total: {
	// 						show: true,
	// 						label: "Total",
	// 						formatter: function (w) {
	// 							// By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
	// 							return 249;
	// 						},
	// 					},
	// 				},
	// 			},
	// 		},
	// 		labels: ["Apples", "Oranges", "Bananas", "Berries"],
	// 	},

	// 	series: [
	// 		{
	// 			type: "radialBar",
	// 			data: [44, 55, 67, 83],
	// 		},
	// 	],
	// };
	//   let option = {
	//     series: [100, 85, 30],
	//     options: {
	//       chart: {
	//         height: 350,
	//         type: "radialBar",
	//       },
	//       plotOptions: {
	//         radialBar: {
	//           dataLabels: {
	//             name: {
	//               fontSize: "22px",
	//             },
	//             value: {
	//               fontSize: "16px",
	//             },
	//             total: {
	//               show: true,
	//               label: "Total",
	//               formatter: function (w) {
	//                 // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
	//                 return 249;
	//               },
	//             },
	//           },
	//         },
	//       },
	//     },
	//   };
	const series = [
		100,
		chartData?.subPercentage ? chartData?.subPercentage : 0,
		chartData?.nonSubPercentage ? chartData?.nonSubPercentage : 0,
	]; //70 percent

	// console.log("series", series);
	const options = {
		labels: ["Total", "Assinante", `cancelamento inscrição`], //label of this diagram
		chart: {
			width: "50%",
		},
		stroke: {
			lineCap: "round",
			// width: "25px",
		},
		colors: ["#EA4C89", "#554886", "#FF6D4D"],
		plotOptions: {
			radialBar: {
				track: {
					margin: 0,
					padding: 10,
				},
				// hollow: {
				// 	margin: 0,
				// 	// size: "70%",
				// 	background: "#fff",
				// },
				dataLabels: {
					name: {
						fontSize: "16px",
					},
					value: {
						fontSize: "15px",
						color: "#111",
					},
					total: {
						show: false,
						label: "100%",
						// fontSize: "px",
						formatter: function (w) {
							// console.log("chart total value :: +", w);
							// By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
							return "";
						},
					},
				},
			},
		},
	};
	return (
		<>
			<Chart
				type='radialBar'
				series={series}
				options={options}
				width='90%'
			/>
		</>
	);
};

export default PieChartReact;
