import { AfterAuthApi } from "..";
import {
  GET_OCCUPATION_AREA,
  EDIT_OCCUPATION_AREA,
  DELETE_OCCUPATION_AREA,
  ADD_OCCUPATION_AREA,
  ACTIVE_OCCUPATION,
} from "../url";

export const getOccupationArea = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_OCCUPATION_AREA, "post")
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const editOccupationArea = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(EDIT_OCCUPATION_AREA, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const deleteOccupationArea = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_OCCUPATION_AREA, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addOccupationArea = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_OCCUPATION_AREA, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const activeOccupationType = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ACTIVE_OCCUPATION, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
