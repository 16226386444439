import { AfterAuthApi } from "..";
import {
  ADD_OFFICE,
  DELETE_OFFICE,
  GET_OFFICE,
  EDIT_OFFICE,
  ACTIVE_OFFICE,
} from "../url";

export const addOfficeType = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_OFFICE, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getOffice = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_OFFICE, "post")
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const deleteOfficeType = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_OFFICE, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const editOfficeType = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(EDIT_OFFICE, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const activeOfficeType = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ACTIVE_OFFICE, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
