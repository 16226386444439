import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { Grid, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { getSubscriberOffice } from "../../helper/API/subscribers";

const ViewOfficeDetail = ({
	open,
	handleClose,
	officeAdvocate,
	setOpenViewDetail,
}) => {
	// console.log("officeAdvocate", officeAdvocate);
	const [office, setOffice] = useState();
	useEffect(() => {
		const submitData = { advocateId: officeAdvocate.id };
		getSubscriberOffice(submitData).then((res) => {
			// console.log("res view office", res);
			if (res.success) {
				// setOpenViewDetail(true);
				setOffice(res.data);
			} else {
				toast.error(res.message);
				handleClose();
			}
		});
	}, []);
	// console.log("office", office);
	return (
		<div>
			<Dialog fullWidth maxWidth={"lg"} open={open} onClose={handleClose}>
				<DialogTitle>Detalhe do escritório</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid
							item
							sm={12}
							md={12}
							lg={12}
							sx={{ display: "flex", justifyContent: "center" }}>
							<div
								style={{
									// border: "1px solid red",
									height: "100px",
									width: "100px",
									borderRadius: "50%",
								}}>
								<img
									src={
										office?.avatar
											? office?.avatar
											: "/assets/images/default-profile.png"
									}
									style={{
										// border: "1px solid red",
										height: "100px",
										width: "100px",
										borderRadius: "50%",
									}}
								/>
							</div>
						</Grid>
						{office?.name && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Nome :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.name}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.email && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									o email :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.email}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.website && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Website :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.website}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.identityId && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									CPF/CNPJ :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.identityId}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.mobileNumber && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Número de celular :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.mobileNumber}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.telephone && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Telephone :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.telephone}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.socialReason && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Razão Social :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.socialReason}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.state && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Estado :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.state}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.district && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Bairro :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.district}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.city && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									cidade :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.city}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.street && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									rua :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.street}
									</span>
								</Typography>
							</Grid>
						)}

						{office?.postal && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Endereço :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.postal}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.officeDesc && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Descrição do escritório :{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.officeDesc}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.room && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									Sala:{" "}
									<span style={{ fontWeight: 500 }}>
										{office?.room}
									</span>
								</Typography>
							</Grid>
						)}
						{office?.socialLink?.length && (
							<Grid item sm={4} md={4} lg={4} mt={2}>
								<Typography sx={{ fontWeight: 900 }}>
									socialLink:{" "}
									{office?.socialLink?.map((obj, i) => (
										<span style={{ fontWeight: 500 }}>
											{obj?.link}
										</span>
									))}
								</Typography>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Perto</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
export default ViewOfficeDetail;
