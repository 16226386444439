import { ApiCall } from "../../helper";
import { LOGIN_USER_SUCCESSFUL, LOGIN_PROCESSING, STOP_LOGIN_PROCESSING, LOGOUT_SUCCESSFUL } from "./type";
import { LOGIN_USER } from "../../helper/url";

export const loginUser = payload => dispatch => {
  return new Promise((resolve, reject) => {
    console.log("payload", payload);
    dispatch({ type: LOGIN_PROCESSING });
    ApiCall(LOGIN_USER, "post", payload)
      .then(loginUser => {
        console.log("loginUser", loginUser);
        dispatch({
          type: LOGIN_USER_SUCCESSFUL,
          payload: loginUser,
        });
        resolve(loginUser.data);
      })
      .catch(error => {
        dispatch({ type: STOP_LOGIN_PROCESSING });
        console.log("error in login action", error.message);
      });
  });
};

export const logout = payload => {
  return {
    type: LOGOUT_SUCCESSFUL,
    payload,
  };
};
