import React from "react";
import Box from "@mui/material/Box";

import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import { experimentalStyled as styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const Img = styled("img")({
	display: "block",
	maxWidth: "20px",
	maxHeight: "20px",
	marginRight: 8,
});

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		borderRadius: 160,
		marginBottom: "3rem",
		// maxWidth: 500,
		"& .MuiTab-textColorSecondary.Mui-selected": {
			color: "#ffff",
			zIndex: 60,
		},
		"& .MuiTabs-indicator": {
			height: "100% !important",
			borderRadius: 160,
			//   bottom: "4px",
			//   left: "6px !important",
			background:
				"linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
		},
		"& .MuiTab-wrapper": {
			textTransform: "capitalize",
			fontSize: 16,
			fontWeight: "bold",
		},
		"& .MuiPaper-elevation1": {
			boxShadow: "unset !important",
		},
	},
	actionContainer: {
		background: "#D6D9FF",
		borderTopRightRadius: "13px",
		borderBottomRightRadius: "13px",
		// padding: "8px 15px",
	},
	title: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: "800",
		// fontSize: "20px",
		// lineHeight: "26px",
	},
	subtitle: {
		color: "#757575",
		fontFamily: "Poppins",
		fontWeight: 400,
		fontSize: 18,
		margin: "0 !important",
	},
	card: {
		// marginTop: "2rem",
		// marginBottom: "1rem",
		boxShadow: "unset !important",
		borderRadius: 11,
	},
	opinion: {
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "20px",
		// lineHeight: "22px",
		// color: "red"
	},
});

const EstatísticasCard = ({ src, total, value }) => {
	const classes = useStyles();
	return (
		<Grid item xs={2} sm={4} md={3}>
			<Card
				variant='elevation'
				className={classes.card}
				sx={{
					backgroundColor: "#fff",
					color: "#000000",
					// border: "2px solid red",
					borderRadius: "18px",
					boxShadow:
						"0px 1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12) !important",
				}}>
				<CardContent
					className={classes.cardContent}
					sx={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
					}}>
					<Box>
						<Box display={"flex"} justifyContent={"space-between"}>
							<h3 className={classes.title}>{value}</h3>
							<Box>
								<Box>
									<Img
										alt='complex'
										src={
											"/assets/images/calender_white.svg"
										}
									/>
								</Box>
							</Box>
						</Box>
						<Box
							p={1}
							display={"flex"}
							justifyContent={"space-between"}
							alignItems={"center"}>
							<Box>
								<Box
									display={"flex"}
									justifyContent={"space-between"}
									alignItems={"center"}>
									<p className={classes.time}>{total}</p>
								</Box>
							</Box>
						</Box>
					</Box>
					<Box
						sx={{
							padding: "2rem",
							background:
								"linear-gradient(134.42deg, #734699 36.93%, #FE2D66 100%)",
							borderRadius: "18px",
						}}>
						<img src={src} />
					</Box>
				</CardContent>
			</Card>
		</Grid>
	);
};

export default EstatísticasCard;
