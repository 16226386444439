import { getAdmin } from "../../helper/API/permission";
import {
	GET_ADMIN_LIST,
	ADMIN_PROCESSING,
	STOP_ADMIN_PROCESSING,
} from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setAdminList = (payload) => {
	// console.log("payload ser", payload);
	return {
		type: GET_ADMIN_LIST,
		payload,
	};
};

export const adminList = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		// console.log("payload", payload);
		dispatch({ type: ADMIN_PROCESSING });
		getAdmin()
			.then((Admin) => {
				// console.log("Admin", Admin);
				dispatch({
					type: GET_ADMIN_LIST,
					payload: Admin.data,
				});
				resolve(Admin.data);
			})
			.catch((error) => {
				dispatch({ type: STOP_ADMIN_PROCESSING });
				// console.log("error in login action", error.message);
			});
	});
};
