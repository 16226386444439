import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import CustomizedSearchBar from "../components/Inputs/SearchBar";
import TableTemplate from "../components/Admin/TableTemplate";
import { getAdmin } from "../helper/API/permission";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { connect } from "react-redux";
import * as adminAction from "../redux/adminList/action";
import SearchInput from "../components/SearchInput";

const Img = styled("img")({
	// margin: 'auto',
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
});

const Admin = ({ adminListFromRedux, adminListData, processing }) => {
	const [tableRows, setTableRows] = React.useState();
	const [loading, setLoading] = React.useState(false);
	const [refresh, setRefresh] = React.useState(1);
	const [searchVal, setSearchVal] = React.useState("");

	const navigate = useNavigate();

	React.useEffect(() => {
		adminListData();
	}, []);

	React.useEffect(() => {
		setTableRows(adminListFromRedux);
	}, [adminListFromRedux]);

	// console.log("tableRows", tableRows);
	const handleAddAdmin = (row) => {
		navigate("/home/acesso");
	};

	// SEARCH IN TABLE
	const onSearchAdmin = () => {
		setSearchVal("");
		setTableRows(adminListFromRedux);
	};

	const onChangeSearch = (event) => {
		setSearchVal(event.target.value);
		if (event.target.value) {
			var updateState = tableRows.filter(({ name }) =>
				name.toLowerCase().includes(event.target.value.toLowerCase())
			);
			setTableRows(updateState);
			// console.log("obj if 1", updateState);
			updateState = [];
		} else if (event.target.value == "") {
			setTableRows(adminListFromRedux);
			// console.log("obj if 2");
		} else {
			setTableRows([]);
			// console.log("obj if 3");
		}
	};

	return (
		<Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
			{/* <CustomizedSearchBar /> */}
			<SearchInput
				onChange={onChangeSearch}
				searchVal={searchVal}
				onClick={onSearchAdmin}
				placeholder='Administrador de pesquisa'
			/>

			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					variant='contained'
					sx={{ marginBottom: 3 }}
					onClick={handleAddAdmin}>
					Adicionar administrador
				</Button>
			</div>
			{processing ? (
				<Loader />
			) : (
				<TableTemplate
					rows={tableRows}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			)}
		</Box>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		adminListData: () => dispatch(adminAction.adminList()),
	};
};
const mapStateToProp = (state) => {
	// console.log("state", state);
	return {
		adminListFromRedux: state?.adminListReducer?.adminList,
		processing: state?.adminListReducer?.processing,
	};
};
export default connect(mapStateToProp, mapDispatchToProp)(Admin);
