import React from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";

import CustomizedSearchBar from "../components/Inputs/SearchBar";
import TableTemplate from "../components/occupation/TableTemplate";

const Img = styled("img")({
  // margin: 'auto',
  display: "block",
  maxWidth: "100%",
  maxHeight: "100%",
});
const Occupation = () => {
  return (
    <div>
      <Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
        {/* <CustomizedSearchBar /> */}
        <TableTemplate />
      </Box>
    </div>
  );
};

export default Occupation;
