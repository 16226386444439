import { getNoteType } from "../../helper/API/note";
import { GET_NOTE_LIST, NOTE_PROCESSING, STOP_NOTE_PROCESSING } from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setPlanList = (payload) => {
	// console.log("payload ser", payload);
	return {
		type: GET_NOTE_LIST,
		payload,
	};
};

export const noteList = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		// console.log("payload", payload);
		dispatch({ type: NOTE_PROCESSING });
		getNoteType()
			.then((note) => {
				// console.log("note", note);
				dispatch({
					type: GET_NOTE_LIST,
					payload: note.data,
				});
				resolve(note.data);
			})
			.catch((error) => {
				dispatch({ type: STOP_NOTE_PROCESSING });
				// console.log("error in login action", error.message);
			});
	});
};
