import { getOffice } from "../../helper/API/office";
import {
	GET_OFFICE_LIST,
	OFFICE_PROCESSING,
	STOP_OFFICE_PROCESSING,
} from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setOfficeList = (payload) => {
	// console.log("payload ser", payload);
	return {
		type: GET_OFFICE_LIST,
		payload,
	};
};

export const officeList = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		// console.log("payload", payload);
		dispatch({ type: OFFICE_PROCESSING });
		getOffice()
			.then((office) => {
				// console.log("office", office);
				dispatch({
					type: GET_OFFICE_LIST,
					payload: office.data,
				});
				resolve(office.data);
			})
			.catch((error) => {
				dispatch({ type: STOP_OFFICE_PROCESSING });
				// console.log("error in login action", error.message);
			});
	});
};
