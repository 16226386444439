import { GET_NOTE_LIST, NOTE_PROCESSING, STOP_NOTE_PROCESSING } from "./type";

const defaultReducer = {
  noteList: [],
  processing: false,
};

const noteListListReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_NOTE_LIST:
      return {
        ...state,
        processing: false,

        noteList: action.payload,
      };

    case NOTE_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case STOP_NOTE_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default noteListListReducer;
