import logo from "./logo.svg";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { HomeLayout } from "./layout/HomeLayout";
import ProtectedLayout from "./layout/ProtectedLayout";
import { LoginPage } from "./page/LoginPage";
import Office from "./page/Office";
import VisãoGeral from "./page/VisãoGeral";
import { Listagem } from "./page/Listagem";
import Acesso from "./page/Acesso";
import Plan from "./page/Plan";
import Note from "./page/Note";
import Occupation from "./page/Occupation";
import ForgotPassword from "./page/ForgotPassword";
import VerifyAndUpdatePassword from "./page/VerifyAndUpdatePassword";
import Admin from "./page/Admin";
import Subscribers from "./page/Subscribers";
import Client from "./page/Client";
import ErrorPage from "./page/ErrorPage";
import { connect } from "react-redux";
import Category from "./page/Category";

function App({ userData }) {
	// console.log("userData app::", userData);
	return (
		<>
			<Routes>
				<Route element={<HomeLayout />}>
					<Route path='/' element={<LoginPage />} />
					<Route
						path='/forgot-password'
						element={<ForgotPassword />}
					/>
					<Route
						path='/verify-password'
						element={<VerifyAndUpdatePassword />}
					/>
					<Route path='/error' element={<ErrorPage />} />
				</Route>
				<Route path='/home' element={<ProtectedLayout />}>
					<Route path='*' element={<Navigate to='error' replace />} />
					{userData?.office_type ? (
						<Route path='office' element={<Office />} />
					) : (
						<Route path='error' element={<ErrorPage />} />
					)}
					{userData?.dashboard ? (
						<Route path='/home' element={<VisãoGeral />} />
					) : (
						<Route path='error' element={<ErrorPage />} />
					)}
					{/* <Route path='listagem' element={<Listagem />} /> */}
					{userData?.manage_admins ? (
						<Route path='acesso' element={<Acesso />} />
					) : (
						<Route path='error' element={<ErrorPage />} />
					)}
					{userData?.plan ? (
						<Route path='plan' element={<Plan />} />
					) : (
						<Route path='error' element={<ErrorPage />} />
					)}
					{userData?.notes_type ? (
						<Route path='note' element={<Note />} />
					) : (
						<Route path='error' element={<ErrorPage />} />
					)}
					{userData?.occupation_area ? (
						<Route path='occupation' element={<Occupation />} />
					) : (
						<Route path='error' element={<ErrorPage />} />
					)}
					{userData?.manage_admins ? (
						<Route path='admin' element={<Admin />} />
					) : (
						<Route path='error' element={<ErrorPage />} />
					)}
					{userData?.manage_advocate ? (
						<>
							<Route
								path='subscribers'
								element={<Subscribers />}
							/>
							<Route path='clients' element={<Client />} />
						</>
					) : (
						<Route path='error' element={<ErrorPage />} />
					)}
					{/* {userData?.manage_advocate ? ( */}
					<>
						<Route path='category' element={<Category />} />
					</>
					{/* ) : (
						<Route path='error' element={<ErrorPage />} />
					)} */}
				</Route>
			</Routes>
		</>
	);
}

const mapStateToProp = (state) => {
	// console.log("state ::", state);
	return {
		userData: state?.loginUsers?.loginUser?.data?.data?.permission,
	};
};

export default connect(mapStateToProp)(App);
