import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import CustomizedSearchBar from "../components/Inputs/SearchBar";
import TableTemplate from "../components/Subscribers/TableTemplate";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { getSubscribers } from "../helper/API/subscribers";
import RecordFound from "../components/RecordFound/RecordFound";
import { connect } from "react-redux";
import * as subscriberAction from "../redux/subscriberlist/action";
import SearchInput from "../components/SearchInput";

const Img = styled("img")({
	// margin: 'auto',
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
});

const Subscribers = ({
	subscriberListData,
	subscriberListFromRedux,
	processing,
}) => {
	const [tableRows, setTableRows] = React.useState(subscriberListFromRedux);
	const [loading, setLoading] = React.useState(false);
	const [refresh, setRefresh] = React.useState(1);
	const [searchVal, setSearchVal] = React.useState("");

	const navigate = useNavigate();

	React.useEffect(() => {
		subscriberListData();
	}, []);

	React.useEffect(() => {
		setTableRows(subscriberListFromRedux);
	}, [subscriberListFromRedux]);
	// console.log("processing", processing);
	// console.log("subscriberListFromRedux", subscriberListFromRedux);
	const handleAddAdmin = (row) => {
		navigate("/home/acesso");
	};

	// SEARCH IN TABLE
	const onSearchSubscriber = () => {
		setSearchVal("");
		setTableRows(subscriberListFromRedux);
	};

	const onChangeSearch = (event) => {
		setSearchVal(event.target.value);
		if (event.target.value) {
			var updateState = tableRows.filter(({ name }) =>
				name.toLowerCase().includes(event.target.value.toLowerCase())
			);
			setTableRows(updateState);
			// console.log("obj if 1", updateState);
			updateState = [];
		} else if (event.target.value == "") {
			setTableRows(subscriberListFromRedux);
			// console.log("obj if 2");
		} else {
			setTableRows([]);
			// console.log("obj if 3");
		}
	};

	return (
		<Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
			<SearchInput
				onChange={onChangeSearch}
				searchVal={searchVal}
				onClick={onSearchSubscriber}
				placeholder='Procurar Escritórios Assinantes'
			/>

			{loading ? (
				<Loader />
			) : tableRows?.length ? (
				<TableTemplate
					rows={tableRows}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			) : (
				<RecordFound label='Nenhum registro foi encontrado' />
			)}
		</Box>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		subscriberListData: () => dispatch(subscriberAction.subscriberList()),
	};
};
const mapStateToProp = (state) => {
	// console.log("state", state);
	return {
		subscriberListFromRedux: state?.subscriberListReducer?.subscriberList,
		processing: state?.subscriberListReducer?.processing,
	};
};
export default connect(mapStateToProp, mapDispatchToProp)(Subscribers);
