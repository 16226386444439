import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
// import { useTranslation } from "react-i18next";
import { IconButton, InputAdornment, OutlinedInput } from "@mui/material";
import { Visibility } from "@mui/icons-material";
import StyledButton from "../components/StyledButton";
import styled from "@emotion/styled";
import CustomizedSearchBar from "../components/Inputs/SearchBar";
import TableTemplate from "../components/listagem/TableTemplate";

const Img = styled("img")({
	// margin: 'auto',
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
});

export const Listagem = () => {
	const { login } = useAuth();
	// const { t } = useTranslation();
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		login({
			email: data.get("email"),
			password: data.get("password"),
		});
	};

	return (
		<Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
			<CustomizedSearchBar />
			<TableTemplate />
		</Box>
	);
};
