import { ApiCall } from "../../helper";
import { getPlan } from "../../helper/API/plan";
import { GET_PLAN_LIST, PLAN_PROCESSING, STOP_PLAN_PROCESSING } from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setPlanList = (payload) => {
	// console.log("payload ser", payload);
	return {
		type: GET_PLAN_LIST,
		payload,
	};
};

export const planList = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		// console.log("payload", payload);
		dispatch({ type: PLAN_PROCESSING });
		getPlan()
			.then((plan) => {
				// console.log("plan", plan);
				dispatch({
					type: GET_PLAN_LIST,
					payload: plan.data,
				});
				resolve(plan.data);
			})
			.catch((error) => {
				dispatch({ type: STOP_PLAN_PROCESSING });
				// console.log("error in login action", error.message);
			});
	});
};
