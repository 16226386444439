import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
// import { useNavigate } from "react-router-dom";
import * as loginAction from "../redux/login/action";
import { toast } from "react-toastify";
import { connect } from "react-redux";

const AuthContext = createContext();

export const AuthProvider = ({ children, loginAdmin }) => {
	const [user, setUser] = useLocalStorage("user", null);
	const navigate = useNavigate();

	const login = async (data) => {
		// console.log("login data", data);
		loginAdmin(data).then((res) => {
			// console.log("res in auth", res);
			if (res.success) {
				toast.success(res.message);
				setUser(data);
				navigate("/home", { replace: true });
			} else {
				toast.error(res.message);
			}
		});
		// setUser(data);
		// navigate("/home", { replace: true });
	};

	const logout = () => {
		setUser(null);
		navigate("/", { replace: true });
	};

	const value = useMemo(
		() => ({
			user,
			login,
			logout,
		}),
		[user]
	);

	// console.log("value::: ", value);
	return (
		<AuthContext.Provider value={value}>{children}</AuthContext.Provider>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		loginAdmin: (loginData) => dispatch(loginAction.loginUser(loginData)),
	};
};

export default connect(null, mapDispatchToProp)(AuthProvider);

export const useAuth = () => {
	return useContext(AuthContext);
};
