import React, { useEffect, useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
	Box,
	Card,
	CardContent,
	FormControl,
	Typography,
	TextField,
	InputLabel,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	Divider,
	Checkbox,
} from "@mui/material";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { makeStyles } from "@material-ui/core/styles";
import { alpha } from "@mui/material/styles";

import { useNavigate, useParams } from "react-router-dom";
import { getClientesInfo } from "../components/Acesso/data";
import { Button } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { addAdmin, getPermissionList } from "../helper/API/permission";
import { toast } from "react-toastify";

const Img = styled("img")({
	display: "block",
	//   maxWidth: "40%",
	//   maxHeight: "100%",
});
const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		borderRadius: 160,
		// maxWidth: 500,
		"& .MuiFormControlLabel-label": {
			fontWeight: "700 !important",
		},
		"& .MuiTab-textColorSecondary.Mui-selected": {
			color: "#ffff",
			zIndex: 60,
		},
		"& .MuiCardContent-root": {
			// padding: "2rem !important",
		},
		"& .MuiTextField-root": {
			margin: "11px",
		},
		"& .MuiPaper-elevation1": {
			boxShadow: "unset !important",
			borderRadius: "19px !important",
		},
		"& .MuiOutlinedInput-notchedOutline": {
			borderColor: "#DBD7F4 !important",
		},
		"& .MuiOutlinedInput-notchedOutline > legend > span": {
			color: "#DBD7F4 !important",
		},
		"$ .MuiInputBase-root": {
			color: "#2C3143 !important",
		},
		"& .MuiList-root": {
			display: "flex !important",
			width: "25%",
		},
		"& .MuiListItemText-primary": {
			fontWeight: "700 !important",
		},
	},
	bold: {
		fontWeight: "700 !important",
	},
	card: {
		marginBottom: "1rem",
		boxShadow: "unset !important",
		borderRadius: 11,
	},
	CardContent: {
		paddingBottom: "0 !important",
	},
	total: {
		fontFamily: "Poppins",
		color: "#747B96",
		fontWeight: 500,
	},
	amount: {
		color: "#747BE6 !important",
		fontWeight: "700 !important",
	},
	mainTitle: {
		fontSize: 22,
		fontWeight: 700,
		margin: "1rem 0",
		color: "#171B1E",
	},
	subTitle: {
		fontSize: 20,
		fontWeight: 700,
		margin: "1rem 0",
		color: "#171B1E",
	},
	title: {
		color: "#0A0615",
		fontFamily: "Poppins",
		fontWeight: 500,
		fontSize: 20,
		margin: "0 !important",
	},
	subtitle: {
		color: "#404B52",
		fontFamily: "Open Sans",
		fontWeight: 400,
		fontSize: 14,
		margin: "0 !important",
	},
	listContent: {
		padding: "unset !important",
	},
	listItem: {
		flexBasis: "25% !important",
	},
});
const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 15,
		position: "relative",
		color: "#171B1E",
		backgroundColor: "##FFFFFF",
		border: "2px solid #D7D7D7",
		fontSize: 16,
		width: "100%",
		padding: "15px 18px",
		transition: theme.transitions.create([
			"border-color",
			"background-color",
			"box-shadow",
		]),
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"-apple-system",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			"Roboto",
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
		"&:focus": {
			boxShadow: `${alpha(
				theme.palette.primary.main,
				0.25
			)} 0 0 0 0.2rem`,
			borderColor: theme.palette.primary.main,
		},
	},
}));
const Acesso = () => {
	const classes = useStyles();
	const navigate = useNavigate();
	const [permissionList, setPermissionList] = useState([]);
	const [permissions, setPermissions] = useState([]);
	const [info, setInfo] = useState({
		name: "",
		email: "",
	});

	//GET PERMISSION LIST
	useEffect(() => {
		getPermissionList().then((res) => {
			// console.log("permission list", res);
			if (res.success) {
				setPermissionList(res.data);
			}
		});
	}, []);

	const handleChecke = (e, data, index) => {
		// setPermissions([...permissions, data]);
		const permissionArr = permissions.indexOf(data);
		// console.log("permissionArr", permissionArr);
		if (permissionArr === -1) {
			setPermissions([...permissions, data]);
		} else {
			// console.log("permissionArr", permissionArr);
			const permissionRemove = permissions;
			const ab = permissionRemove.splice(permissionArr, 1);
			// console.log("permissionRemove", permissionRemove);
			setPermissions(permissionRemove);
		}
	};
	const handleChange = (e) => {
		setInfo({
			...info,
			[e.target.name]: e.target.value,
		});
	};

	const onSubmitAdmin = () => {
		const submitData = {
			...info,
			permission: permissions,
		};
		addAdmin(submitData).then((res) => {
			// console.log("Admin add res", res);
			if (res.success) {
				toast.success(res.message);
				navigate("/home/admin");
			} else {
				toast.error(res.message);
			}
		});
		// console.log("submitData", submitData);
	};

	// console.log("permissions", permissions);
	// console.log("info", info);
	return (
		<Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
			<div className={classes.root}>
				<Card variant='elevation' className={classes.card}>
					<CardContent sx={{ padding: "2rem" }}>
						<div className={classes.mainTitle}>
							{" "}
							Cadastrar novo usuário{" "}
						</div>
						<div className={classes.subTitle}> Cadastro </div>
						<Box component='form' noValidate>
							<Box
								sx={{
									display: "grid",
									gridTemplateColumns: { sm: "auto auto" },
									gap: "5rem",
									paddingBottom: "2rem",
								}}>
								<FormControl variant='standard'>
									<InputLabel
										shrink
										htmlFor='bootstrap-input'>
										Nome
									</InputLabel>
									<BootstrapInput
										// defaultValue="#IdEscritorio"
										name='name'
										id='bootstrap-input'
										onChange={handleChange}
									/>
								</FormControl>

								<FormControl variant='standard'>
									<InputLabel
										shrink
										htmlFor='bootstrap-input'>
										E-mail
									</InputLabel>
									<BootstrapInput
										// defaultValue="Usuário 01"
										name='email'
										id='bootstrap-input'
										onChange={handleChange}
									/>
								</FormControl>
							</Box>
							<Box
								sx={{
									display: "grid",
									gridTemplateColumns: { sm: "auto auto" },
									gap: "5rem",
									paddingBottom: "2rem",
								}}>
								{/* <FormControl variant="standard">
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Login do Usuário
                  </InputLabel>
                  <BootstrapInput
                    defaultValue="user@gmail.com"
                    id="bootstrap-input"
                  />
                </FormControl>

                <FormControl variant="standard">
                  <InputLabel shrink htmlFor="bootstrap-input">
                    Senha do Usuário
                  </InputLabel>
                  <BootstrapInput
                    defaultValue="usuarioadmin@gmail.com"
                    id="bootstrap-input"
                  />
                </FormControl> */}
							</Box>
						</Box>
					</CardContent>
				</Card>
				<Box mt={5} mb={8}>
					<div className={classes.mainTitle}> Permissões </div>

					<Typography
						variant='h6'
						noWrap
						component='div'
						sx={{ fontWeight: "300 !important" }}>
						Clique nos itens para conceder permissão para o usuário
					</Typography>
				</Box>
				<div
					style={{
						display: "flex",
						position: "realtive",
						justifyContent: "space-around",
						alignItems: "center",
						marginTop: "1.5%",
						cursor: "pointer",
					}}>
					<Card
						sx={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-start",
							width: "100%",
						}}>
						<CardContent
							className={classes.listContent}
							sx={{ width: "100%" }}>
							<Box
								sx={{
									width: "100%",

									bgcolor: "background.paper",
								}}>
								<nav
									aria-label='main mailbox folders'
									style={{ padding: "0.3rem 3rem" }}>
									<List>
										<ListItem disablePadding>
											<ListItemButton>
												<ListItemText
													className={classes.bold}
													primary='Permissões'
												/>
											</ListItemButton>
										</ListItem>
									</List>
								</nav>
								<Divider />
								{permissionList?.map((obj, indx) => (
									<Box
										sx={{
											display: "flex",
											justifyContent: "start",
											alignItems: "center",
											mb: 2,
											padding: "0.3rem 3rem",
										}}
										key={indx}>
										<Checkbox
											// defaultChecked={
											//   inviteId && (inviteId.indexOf(obj.id) >= 0 ? true : false)
											// }
											onChange={(e) =>
												handleChecke(e, obj, indx)
											}
										/>
										<Typography sx={{ ml: 1 }}>
											{obj.label}
										</Typography>
									</Box>
								))}
							</Box>
						</CardContent>
					</Card>
				</div>
				<Box
					display={"flex"}
					justifyContent={"flex-end"}
					alignItems={"center"}
					m={2}>
					<Button
						sx={{
							my: 4,
							borderRadius: "15px",
							padding: "12px 30px",
							textTransform: "capitalize",
							fontSize: "1.3rem",
						}}
						variant='contained'
						onClick={onSubmitAdmin}>
						Liberar Acessos
					</Button>
				</Box>
			</div>
		</Box>
	);
};

export default Acesso;
