import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { deleteOfficeType } from "../../helper/API/office";
import { CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import * as officeAction from "../../redux/officeList/action";

const DeleteModal = ({
  open,
  handleClose,
  data,
  setRefresh,
  refresh,
  officeListData,
}) => {
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = () => {
    setLoading(true);
    const submitData = {
      id: data.id,
    };
    deleteOfficeType(submitData).then((res) => {
      if (res.success) {
        handleClose();
        officeListData();
        toast.success(res.message);
        setLoading(false);
      } else {
        toast.error(res.message);
        setLoading(false);
      }
    });
  };
  return (
    <div>
      <Dialog fullWidth maxWidth={"sm"} open={open} onClose={handleClose}>
        <DialogTitle>Excluir escritório</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja excluir o tipo do Office?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" color="inherit">
            Não
          </Button>
          <Button
            onClick={handleSubmit}
            variant="outlined"
            sx={{ position: "relative" }}
            disabled={loading}
            color="error"
          >
            Sim
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapDispatchToProp = (dispatch) => {
  return {
    officeListData: () => dispatch(officeAction.officeList()),
  };
};
export default connect(null, mapDispatchToProp)(DeleteModal);
