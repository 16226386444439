import { Link, Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import Navbar from "../shareComponent/Navbar";
import Box from "@mui/material/Box";

import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import { connect } from "react-redux";

const ProtectedLayout = ({ userData }) => {
	const { user } = useAuth();
	const outlet = useOutlet();

	if (!user) {
		return <Navigate to='/' />;
	}

	return (
		<div>
			<Navbar
				pages={[
					{
						label: "Painel",
						path: "/",
						img: "/assets/images/office.png",
					},
					// {
					//   label: "Listagem",
					//   path: "listagem",
					//   img: "/assets/images/customers.png",
					// },

					{
						label: "Escritório",
						path: "office",
						img: "/assets/images/customers.png",
					},
					{
						label: "Ocupação",
						path: "occupation",
						img: "/assets/images/ragister.png",
					},
					{
						label: "Observação",
						path: "note",
						img: "/assets/images/law_suit.png",
					},
					{
						label: "Plano",
						path: "plan",
						img: "/assets/images/dollar.png",
					},
					{
						label: "More +",
						path: "more",
						img: "/assets/images/mail.png",
					},
				]}
			/>
			{outlet}
		</div>
	);
};

const mapStateToProp = (state) => {
	// console.log("state ::", state);
	return {
		userData: state?.loginUsers?.loginUser?.data?.data?.permission,
	};
};
export default connect(mapStateToProp)(ProtectedLayout);
