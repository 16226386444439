import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import TableComponent from "./TableComponent";
import { experimentalStyled as styled } from "@mui/material/styles";
import { getOccupationArea } from "../../helper/API/occupation";
import Loader from "../Loader";
import AddOccupationModal from "./AddOccupationModal";
import RecordFound from "../RecordFound/RecordFound";
import { connect } from "react-redux";
import * as occupationAction from "../../redux/occupationList/action";
import SearchInput from "../SearchInput/index";

const Img = styled("img")({
	display: "block",
});

const TableTemplate = ({
	occupationListData,
	occupationListFromRedux,
	processing,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const [tableRows, setTableRows] = React.useState(occupationListFromRedux);
	const [loading, setLoading] = React.useState(false);
	const [refresh, setRefresh] = React.useState(1);
	const [addOccupation, setAddOccupation] = React.useState(false);
	const [searchVal, setSearchVal] = React.useState("");

	React.useEffect(() => {
		occupationListData();
	}, [refresh]);

	React.useEffect(() => {
		setTableRows(occupationListFromRedux);
	}, [occupationListFromRedux]);

	const handleAddOccupation = (row) => {
		setAddOccupation(true);
	};

	// SEARCH IN TABLE
	const onSearchOccupation = () => {
		setSearchVal("");
		setTableRows(occupationListFromRedux);
	};

	const onChangeSearch = (event) => {
		setSearchVal(event.target.value);
		if (event.target.value) {
			var updateState = tableRows.filter(({ label }) =>
				label.toLowerCase().includes(event.target.value.toLowerCase())
			);
			setTableRows(updateState);
			updateState = [];
			// console.log("obj if 1");
		} else if (event.target.value == "") {
			setTableRows(occupationListFromRedux);
			// console.log("obj if 2");
		} else {
			setTableRows([]);
			// console.log("obj if 3");
		}
	};

	return (
		<div>
			<SearchInput
				onChange={onChangeSearch}
				searchVal={searchVal}
				onClick={onSearchOccupation}
				placeholder='Pesquisar ocupação'
			/>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					variant='contained'
					sx={{ marginBottom: 3 }}
					onClick={handleAddOccupation}>
					Adicionar tipo de ocupação
				</Button>
			</div>
			{/* {loading ? (
        <Loader />
      ) : (
        <TableComponent
          rows={tableRows}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )} */}
			{processing ? (
				<Loader />
			) : tableRows.length ? (
				<TableComponent
					rows={tableRows}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			) : (
				<RecordFound label='Nenhum registro foi encontrado' />
			)}
			{addOccupation && (
				<AddOccupationModal
					open={addOccupation}
					handleClose={() => setAddOccupation(false)}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			)}
		</div>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		occupationListData: () => dispatch(occupationAction.occupationList()),
	};
};
const mapStateToProp = (state) => {
	// console.log("state", state);
	return {
		occupationListFromRedux:
			state?.occupationListListReducer?.occupationList,
		processing: state?.occupationListListReducer?.processing,
	};
};

export default connect(mapStateToProp, mapDispatchToProp)(TableTemplate);
