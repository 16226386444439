import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Paper,
	Switch,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from "@material-ui/core/styles";
// import EditOccupationModal from "./EditOccupationModal";
import Loader from "../Loader";
import DeleteModal from "./DeleteModal";
import EditNoteModal from "./EditNoteModal";
import { withStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { activeNoteType } from "../../helper/API/note";
import RecordFound from "../RecordFound/RecordFound";

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...{
					bgcolor: "#fff",
				},
			}}>
			<Typography
				sx={{ flex: "1 1 100%", fontWeight: "bold" }}
				variant='h6'
				id='tableTitle'
				component='div'>
				Cliente
			</Typography>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: "Etiqueta",
		numeric: false,
		disablePadding: false,
		label: "Etiqueta",
	},
	{
		id: "Ativa",
		numeric: false,
		disablePadding: false,
		label: "Ativa",
	},
	{
		id: "editar",
		numeric: true,
		disablePadding: false,
		label: "Ação",
	},
];
const useStyles = makeStyles({
	tableBody: {
		"& > :not(:last-child)": {
			borderBottom: "25px solid red",
		},
	},
	root: {
		"& .MuiTable-root, .MuiTableBody-root, .MuiPaper-root": {
			backgroundColor: "transparent !important",
			boxShadow: "unset !important",
		},
		"& .MuiTableRow-root": {
			backgroundColor: "#ffff !important",
			//   marginBottom: "10px !important",
			// borderBottom: "5px solid #F8F5FA",
		},
		"& .MuiTableCell-root MuiTableCell-head": {
			fontSize: "21px",
			fontFamily: "Poppins",
			color: "#464A53 !important",
		},
		"& .MuiTableCell-root MuiTableCell-body": {
			fontSize: "16px",
			fontFamily: "Poppins",
		},
	},
});
function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort } = props;

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={"left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sx={{
							fontSize: "15px",
							fontWeight: "bold",
							color: "#809FB8 !important",
						}}
						sortIcon='hide'
						sortDirection={false}>
						<TableSortLabel>{headCell.label}</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

export default function EnhancedTable({ rows, setRefresh, refresh }) {
	const CustomSwitch = withStyles({
		switchBase: {
			"&$disabled": {
				color: "#734699",
				opacity: 1,
			},
			"&$disabled + $track": {
				backgroundColor: "#734699",
				opacity: 0.5,
			},
		},
		track: {},
		disabled: {},
	})(Switch);

	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [deleteOccupation, setDeleteOccupation] = React.useState(false);
	const [editData, setEditData] = React.useState();
	const [deleteData, setDeleteData] = React.useState();
	///////**********************************************//////// */
	const [order, setOrder] = React.useState("asc");
	const [openViewDetail, setOpenViewDetail] = React.useState(false);
	const [clientDetail, setClientDetail] = React.useState();

	const [orderBy, setOrderBy] = React.useState("data");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;
	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
	const totalPage = Math.ceil(rows.length / rowsPerPage);
	const mapPage = Array.from(Array(totalPage).keys());

	///////**********************************************//////// */

	// MODAL OPEN CLOSE
	const handleEditNote = (row) => {
		setEditData(row);
		setOpen(true);
	};
	const handleDeleteNote = (row) => {
		setDeleteData(row);
		setDeleteOccupation(true);
	};

	const handleDefault = (e, id) => {
		// const submitData = { id };
		let submitData;
		if (e.target.checked) {
			submitData = { id, action: "active" };
		} else {
			submitData = { id, action: "inactive" };
		}

		activeNoteType(submitData).then((res) => {
			// console.log("res note: ", res);
			if (res.success) {
				toast.success(res.message);
				setRefresh(refresh + 1);
			} else {
				toast.error(res.message);
				setRefresh(refresh + 1);
			}
		});
	};

	return (
		<Box sx={{ width: "100%" }} className={classes.root}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<TableContainer>
					<Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle'>
						<EnhancedTableHead />
						<TableBody py={2}>
							{stableSort(rows, getComparator(order, orderBy))
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)
								.map((row, index) => (
									<TableRow
										hover
										style={{
											marginBottom: "10px !important",
										}}
										tabIndex={-1}
										key={index}>
										<TableCell
											align='left'
											style={{
												color: "#3D4655",
												fontWeight: "bold",
												fontSize: "16px",
											}}>
											{row.label}
										</TableCell>
										<TableCell
											align='left'
											style={{
												color: "#3D4655",
												fontWeight: "bold",
												fontSize: "16px",
											}}>
											<CustomSwitch
												defaultChecked={row?.isActive}
												// disabled={row?.isDefault}
												onChange={(e) =>
													handleDefault(e, row.id)
												}
												style={{
													color: "#734699",
													fontWeight: "bold",
													// fontSize: "16px",
												}}
											/>
										</TableCell>
										<TableCell
											align='left'
											style={{
												color: "#7F63F4",
												fontSize: "16px",
											}}
											colSpan={2}>
											<Button
												variant='outlined'
												color='inherit'
												sx={{ marginRight: 2 }}
												onClick={() =>
													handleEditNote(row)
												}>
												{" "}
												Editar
											</Button>
											<Button
												variant='outlined'
												color='error'
												onClick={() =>
													handleDeleteNote(row)
												}>
												{" "}
												Excluir
											</Button>
										</TableCell>
									</TableRow>
								))}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						labelRowsPerPage={"Mostrar até:"}
						component='div'
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>

					{/* <Box
						display='flex'
						justifyContent='flex-end'
						alignItems='center'>
						{mapPage.map((obj, i) => {
							const activePageColor =
								i === page ? "#7F63F4" : "#fff";
							const activePageFontColor =
								i === page ? "#fff" : "#ABAFB3";
							return (
								<Box
									sx={{
										textAlign: "right",
										background: activePageColor,
										padding: "14px",
										marginRight: "4px",
										borderRadius: "50%",
										fontWeight: 700,
										color: activePageFontColor,
									}}>
									{" "}
									{i < 9 ? "0" : ""}
									{i + 1}
								</Box>
							);
						})}
					</Box> */}
				</>
			</Paper>
			{open && (
				<EditNoteModal
					open={open}
					handleClose={() => setOpen(false)}
					data={editData}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			)}
			{deleteOccupation && (
				<DeleteModal
					open={deleteOccupation}
					handleClose={() => setDeleteOccupation(false)}
					data={deleteData}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			)}
		</Box>
	);
}
