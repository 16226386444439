import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Paper,
	Checkbox,
	IconButton,
	Tooltip,
	FormControlLabel,
	Switch,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@mui/material";
import { InsertDriveFileOutlined, Done } from "@mui/icons-material";

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...{
					bgcolor: "#fff",
				},
			}}>
			<Typography
				sx={{ flex: "1 1 100%", fontWeight: "bold" }}
				variant='h6'
				id='tableTitle'
				component='div'>
				Assinantes
			</Typography>

			<Button
				sx={{
					padding: "8px 18px",
					borderRadius: "11px",
					textTransform: "capitalize",
				}}
				variant='contained'
				color='primary'>
				<img
					src='/assets/images/downloadTxt.png'
					alt='downloadTxtIcon'
					width={20}
					height={20}
					style={{ marginRight: 12 }}
				/>
				Tabelas.txt
			</Button>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: "noma",
		numeric: false,
		disablePadding: false,
		label: "Nome do cliente",
	},
	{
		id: "forma",
		numeric: true,
		disablePadding: false,
		label: "Forma de pag.",
	},
	{
		id: "valor",
		numeric: true,
		disablePadding: false,
		label: "Valor",
	},
	{
		id: "email",
		numeric: true,
		disablePadding: false,
		label: "Email",
	},
	{
		id: "tipo",
		numeric: true,
		disablePadding: false,
		label: "Tipo de plano  Status",
	},
	{
		id: "status",
		numeric: true,
		disablePadding: false,
		label: "Status",
	},
];
const useStyles = makeStyles({
	tableBody: {
		"& > :not(:last-child)": {
			borderBottom: "25px solid red",
		},
	},
	root: {
		"& .MuiTable-root, .MuiTableBody-root, .MuiPaper-root": {
			backgroundColor: "transparent !important",
			boxShadow: "unset !important",
		},
		"& .MuiTableRow-root": {
			backgroundColor: "#ffff !important",
			//   marginBottom: "10px !important",
			// borderBottom: "5px solid #F8F5FA",
		},
		"& .MuiTableCell-root MuiTableCell-head": {
			fontSize: "21px",
			fontFamily: "Poppins",
			color: "#464A53 !important",
		},
		"& .MuiTableCell-root MuiTableCell-body": {
			fontSize: "16px",
			fontFamily: "Poppins",
		},
	},
});
function EnhancedTableHead(props) {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={"left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						// className={classes.tableHead}
						sx={{
							fontSize: "15px",
							fontWeight: "bold",
							color: "#809FB8 !important",
						}}
						// sortDirection={orderBy === headCell.id ? order : false}>
						sortIcon='hide'
						sortDirection={false}>
						<TableSortLabel
							//   active={orderBy === headCell.id}
							IconComponent={false}
							direction={orderBy === headCell.id ? order : "asc"}
							//   onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === "desc"
										? "sorted descending"
										: "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({ rows }) {
	console.log("default data", rows);
	const classes = useStyles();
	const [order, setOrder] = React.useState("asc");
	const [orderBy, setOrderBy] = React.useState("data");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event) => {
		setDense(event.target.checked);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;
	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
	const totalPage = Math.ceil(rows.length / rowsPerPage);
	// console.log("totalpage", totalPage);
	// console.log("page", page);
	const mapPage = Array.from(Array(totalPage).keys());
	// console.log("mapPage", mapPage);
	// console.log("rowsPerPage", rowsPerPage);
	// console.log("page * rowsPerPage", page * rowsPerPage);
	// console.log(
	// 	"page * rowsPerPage + rowsPerPage",
	// 	page * rowsPerPage + rowsPerPage
	// );
	return (
		<Box sx={{ width: "100%" }} className={classes.root}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar />
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={dense ? "small" : "medium"}>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody py={2}>
							{/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
							{stableSort(rows, getComparator(order, orderBy))
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)
								.map((row, index) => {
									{
										/* const isItemSelected = isSelected(row.name); */
									}
									return (
										<TableRow
											hover
											style={{
												marginBottom: "10px !important",
											}}
											tabIndex={-1}
											key={row.name}>
											<TableCell
												align='left'
												style={{
													color: "#3D4655",
													fontWeight: "bold",
													fontSize: "16px",
												}}>
												{row.name}
											</TableCell>
											<TableCell
												align='left'
												style={{
													color: "#3D4655",
													fontWeight: "bold",
													fontSize: "16px",
												}}>
												{row.forma}
											</TableCell>
											<TableCell
												align='left'
												style={{
													color: "#3D4655",
													fontWeight: "bold",
													fontSize: "16px",
												}}>
												{row.valor}
											</TableCell>
											<TableCell
												align='left'
												style={{
													color: "#3D4655",
													fontWeight: "bold",
													fontSize: "16px",
												}}>
												{row.email}
											</TableCell>{" "}
											<TableCell
												align='left'
												style={{
													color: "#3D4655",
													fontWeight: "bold",
													fontSize: "16px",
												}}>
												{row.tipo}
											</TableCell>
											<TableCell
												align='left'
												style={{
													color: "#7F63F4",
													fontSize: "16px",
												}}
												colSpan={2}>
												<Chip
													sx={{
														background:
															row.status == "PADO"
																? "#E3E3E350"
																: "#D9E1E7",
														color:
															row.status == "PADO"
																? "#1AD598"
																: "#99B2C6",
														fontSize: 15,
														fontWeight: 500,
													}}
													label={row.status}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				<>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						labelRowsPerPage={"Mostrar até:"}
						component='div'
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>

					<Box
						display='flex'
						justifyContent='flex-end'
						alignItems='center'>
						{mapPage.map((obj, i) => {
							const activePageColor =
								i === page ? "#7F63F4" : "#fff";
							const activePageFontColor =
								i === page ? "#fff" : "#ABAFB3";
							return (
								<Box
									sx={{
										textAlign: "right",
										background: activePageColor,
										padding: "14px",
										marginRight: "4px",
										borderRadius: "50%",
										fontWeight: 700,
										color: activePageFontColor,
									}}>
									{" "}
									{i < 9 ? "0" : ""}
									{i + 1}
								</Box>
							);
						})}
					</Box>
				</>
			</Paper>
		</Box>
	);
}
