import { ApiCall } from "../index";
import { FORGOT_PASSWORD, VERIFY_FORGOT_PASSWORD } from "../url";

export const forgotPassword = submitData => {
  return new Promise((resolve, reject) => {
    ApiCall(FORGOT_PASSWORD, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};

export const verifyForgotPassword = submitData => {
  return new Promise((resolve, reject) => {
    ApiCall(VERIFY_FORGOT_PASSWORD, "post", submitData)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject();
      });
  });
};
