import { AfterAuthApi } from "..";
import {
	ADD_PLAN,
	DELETE_PLAN,
	GET_PLAN,
	EDIT_PLAN,
	DEFUALT_PLAN,
	TRIAL_PLAN,
} from "../url";

export const addPlans = (submitData) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(ADD_PLAN, "post", submitData)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const getPlan = () => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(GET_PLAN, "post")
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const deletePlans = (submitData) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(DELETE_PLAN, "post", submitData)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const editPlans = (submitData) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(EDIT_PLAN, "post", submitData)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const defaultPlan = (submitData) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(DEFUALT_PLAN, "post", submitData)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const trialPlan = (submitData) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(TRIAL_PLAN, "post", submitData)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};
