import React, { useEffect, useState } from "react";
import MuiAppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";

import Button from "@mui/material/Button";

import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { Grid, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import HoverMenu from "../components/HoverMenu";
import NotificationMenu from "../components/NotificationMenu";
import NavabarDropDown from "./NavbarDropDown";
import { getNotificationCount } from "../helper/API/notification";
import { connect } from "react-redux";

const Img = styled("img")({
	// margin: 'auto',
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
});

const Navbar = ({ pages, userData, userDetails }) => {
	const [anchorElNav, setAnchorElNav] = useState(null);
	const [activeIndex, setActiveIndex] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const [refresh, setRefresh] = useState(0);
	const [anchorElNotification, setAnchorElNotification] = useState(null);
	const [notificationCount, setNotificationCount] = useState();
	const open = Boolean(anchorEl);
	const openNotification = Boolean(anchorElNotification);

	useEffect(() => {
		getNotificationCount().then((res) => {
			// console.log("res count", res);
			if (res.success) {
				setNotificationCount(res.data);
			}
		});
	}, [refresh]);
	// console.log("userDetails :: navbar", userDetails);

	const navigate = useNavigate();
	const { user, logout } = useAuth();

	const handleNotificationClick = (event) => {
		setAnchorElNotification(event.currentTarget);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = (path, index) => {
		// console.log("setActiveIndex", index);
		setActiveIndex(index);
		setAnchorElNav(null);
		if (path) {
			navigate(path);
		}
	};

	const [anchorEle, setAnchorEle] = useState(null);
	const openMenu = Boolean(anchorEle);
	const handleClickMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	return (
		<MuiAppBar
			position='static'
			sx={{ backgroundColor: "#fff", boxShadow: "none" }}>
			<Container maxWidth='xl'>
				<Toolbar disableGutters>
					<Box
						sx={{
							flexGrow: 1,
							display: { xs: "flex", md: "none" },
						}}>
						<IconButton
							size='large'
							aria-label='account of current user'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							color='inherit'
							sx={{ color: "#000" }}>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}>
							{pages?.map((page) => (
								<MenuItem
									key={page.label}
									onClick={() =>
										handleCloseNavMenu(page.path)
									}>
									<Container
										sx={{
											display: "flex",
											justifyContent: "space-between",
											alignItems: "center",
											cursor: "pointer",
										}}>
										<div style={{ width: 25, height: 25 }}>
											<Img src={page.img} />
										</div>

										{page.label}
									</Container>
								</MenuItem>
							))}
							{!!user && (
								<MenuItem key={"logout"} onClick={logout}>
									<Typography textalign='center'>
										Logout
									</Typography>
								</MenuItem>
							)}
						</Menu>
					</Box>

					<Box
						sx={{
							flexGrow: 1,
							display: {
								xs: "none",
								md: "flex",
								backgroundColor: "#fff",
							},
						}}>
						<Grid container spacing={2}>
							<Grid item>
								<Button sx={{ width: 125, height: 125 }}>
									<Img
										alt='complex'
										src='/assets/images/inova_juris_logo.png'
										onClick={() => navigate("/home")}
									/>
								</Button>
							</Grid>
							<Grid item xs={12} sm container>
								<Grid item md={12}>
									<Grid container>
										{pages?.map((page, index) => (
											<Grid
												item
												xs={8}
												md={1}
												key={page.label}
												onClick={() =>
													handleCloseNavMenu(
														page.path === "more"
															? ""
															: page.path,
														index
													)
												}
												sx={{
													mt: 2,
													color: "#734699",
													display: "block",
													display: "flex",
													justifyContent:
														"space-around",
													alignItems: "center",
													cursor: "pointer",
													mx: 3,
													my: 3,
													fontWeight: `${
														index == activeIndex
															? "bold"
															: "300"
													}`,
												}}>
												{/* <Container
                          sx={{
                            display: "flex",
                            // justifyContent: "space-around",
                            alignItems: "center",
                            cursor: "pointer",
                          }}
                        > */}
												{page.label === "More +" ? (
													<>
														<NavabarDropDown />
													</>
												) : (
													<>
														<div
															style={{
																paddingRight:
																	"5px",
															}}>
															<Img
																src={page.img}
																width={20}
																height={20}
															/>
														</div>

														{page.label}
													</>
												)}
											</Grid>
										))}
										<Grid
											item
											xs={8}
											md={1}
											// onClick={() => handleCloseNavMenu(page.path)}
											sx={{
												mt: 2,
												color: "#734699",
												display: "block",
												display: "flex",
												justifyContent: "space-around",
												alignItems: "center",
												cursor: "pointer",
												mx: 3,
												my: 3,
											}}>
											{/* <Button
                        id="demo-positioned-button"
                        aria-controls={
                          openMenu ? "demo-positioned-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                        onClick={handleClickMenu}
                      >
                        More +
                      </Button>
                      <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEle}
                        open={openMenu}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <MenuItem onClick={handleCloseMenu}>Profile</MenuItem>
                        <MenuItem onClick={handleCloseMenu}>
                          My account
                        </MenuItem>
                        <MenuItem onClick={handleCloseMenu}>Logout</MenuItem>
                      </Menu> */}
										</Grid>
										{/* {!!user && (
											<Grid
												item
												xs={8}
												md={2}
												key={"logout"}
												onClick={logout}
												sx={{
													color: "white",
													display: "block",
												}}>
												{"logout"}
											</Grid>
										)} */}
									</Grid>
								</Grid>
								<Grid item md={12}>
									<Grid container>
										{/* <Grid
											item
											xs={7}
											md={8}
											sx={{
												mt: 2,
												mb: 2,
												color: "#734699",
												display: "block",
											}}></Grid> */}

										<Grid
											item
											xs={2}
											md={11}
											sx={{
												color: "#000",
												display: "flex",
												alignItems: "space-between",
												justifyContent: "end",
												mt: 0,
												mb: 2,
											}}>
											<div style={{ margin: "0px 8px" }}>
												<Container
													id='notification-button'
													aria-controls={
														openNotification
															? "notification-button"
															: undefined
													}
													aria-haspopup='true'
													aria-expanded={
														openNotification
															? "true"
															: undefined
													}
													onClick={
														handleNotificationClick
													}
													sx={{
														width: 55,
														height: 55,
														py: 1,
														display: "flex",
														justifyContent:
															"center",
														alignItems: "center",
														background:
															"rgba(185, 97, 254, 0.2)",
														position: "relative",
														borderRadius: "8px",
														cursor: "pointer",
													}}>
													<img
														alt='bell'
														src='/assets/images/bell.png'
														height={25}
													/>
													<Box
														sx={{
															position:
																"absolute",
															top: "-15%",
															right: "-15%",
															border: "1px solid #734699",
															borderRadius: "50%",
															height: "20px",
															width: "20px",
															background:
																"#734699",
															display: "flex",
															justifyContent:
																"center",
														}}>
														<Typography
															textalign='center'
															sx={{
																position:
																	"absolute",
																top: "10%",
																color: "#fff",
																fontSize:
																	"10px",
																fontWeight:
																	"900",
															}}>
															{notificationCount}
														</Typography>
													</Box>
												</Container>
												<NotificationMenu
													open={openNotification}
													handleClick={
														handleNotificationClick
													}
													setAnchorElNotification={
														setAnchorElNotification
													}
													refresh={refresh}
													setRefresh={setRefresh}
													anchorEl={
														anchorElNotification
													}
												/>
											</div>

											{/* <Container
												sx={{
													width: 55,
													height: 55,
													py: 1,
													display: "flex",
													justifyContent: "center",
													background: "#FFEBE7",
													position: "relative",
													borderRadius: "8px",
													alignItems: "center",
												}}>
												<img
													alt='bell'
													src='/assets/images/setting.png'
													height={25}
												/>

												<Box
													sx={{
														position: "absolute",
														top: "-15%",
														right: "-15%",
														border: "1px solid #FF6D4D",
														borderRadius: "50%",
														height: "20px",
														width: "20px",
														background: "#FF6D4D",
													}}>
													<Typography
														textalign='center'
														sx={{
															position:
																"absolute",
															right: "30%",
															top: "-20%",
															color: "#fff",
															fontSize: "20px",
															fontWeight: "900",
														}}>
														!
													</Typography>
												</Box>
											</Container> */}
											<div style={{ margin: "0px 8px" }}>
												<Container
													id='basic-button'
													aria-controls={
														open
															? "basic-menu"
															: undefined
													}
													aria-haspopup='true'
													aria-expanded={
														open
															? "true"
															: undefined
													}
													onClick={handleClick}
													sx={{
														cursor: "pointer",
														width: 200,
														py: 1,
														display: "flex",
														alignItems: "center",
														justifyContent:
															"center",
														background: "#734699",
														position: "relative",
														height: 56,
														borderRadius: "8px",
														color: "#fff",
														fontSize: "15px",
													}}>
													{userDetails?.name}
													<Box
														sx={{
															position:
																"absolute",
															right: "-20%",
															borderRadius: "50%",
															background: "#000",
															border: "3px solid #fff",
															height: 56,
															width: 56,
															top: "-5%",
															display: "flex",
															alignItems:
																"center",
															justifyContent:
																"center",
														}}>
														<img
															src={
																userDetails?.avatar
																	? userDetails?.avatar
																	: "/assets/images/inova_juris_logo.png"
															}
															textalign='center'
															alt='avatar'
															style={{
																height: 56,
																width: 56,
																border: "3px solid #fff",
																borderRadius:
																	"50%",
															}}
														/>
													</Box>
												</Container>
												<HoverMenu
													open={open}
													handleClick={handleClick}
													handleClose={() =>
														setAnchorEl(null)
													}
													anchorEl={anchorEl}
												/>
											</div>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Box>
				</Toolbar>
			</Container>
		</MuiAppBar>
	);
};

const mapStateToProp = (state) => {
	// console.log("state ::", state);
	return {
		userData: state?.loginUsers?.loginUser?.data?.data?.permission,
		userDetails: state?.loginUsers?.loginUser?.data?.data,
	};
};

export default connect(mapStateToProp)(Navbar);
