import { AfterAuthApi } from "..";
import {
  GET_PERMISSION,
  ADD_ADMIN,
  GET_ADMIN,
  DELETE_ADMIN,
  EDIT_ADMIN,
} from "../url";

export const getPermissionList = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_PERMISSION, "post")
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const addAdmin = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(ADD_ADMIN, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getAdmin = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_ADMIN, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const deleteAdmin = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(DELETE_ADMIN, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const editAdmin = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(EDIT_ADMIN, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
