import { Button } from '@mui/material'
import React from 'react'

const StyledButton = ({ type, fullWidth, variant, btnText, sx }) => {
    return (
        <Button type={type} fullWidth={fullWidth} variant={variant} sx={sx}>
            {btnText}
        </Button>
    )
}

export default StyledButton