import React from "react";
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export default function ChartReact({ chartData, sem }) {
	// console.log("chartData ::", chartData);

	const labels =
		sem == 1
			? ["jan", "Fev", "Mar", "Abr", "Mai", "Jun"]
			: ["Jul", "Ago", "Set", "Out", "Nov", "Dez"];
	const monthData = chartData?.counts?.map((obj) => {
		return {
			month: Number(obj?._id.slice(5, 7)),
			subscriber: obj.subscribers,
		};
	});

	const unSubscriber = chartData?.counts?.map((obj) => {
		return {
			month: Number(obj?._id.slice(5, 7)),
			subscriber: obj.unSubscribers,
		};
	});
	const totalCount = chartData?.counts?.map((obj) => {
		return {
			month: Number(obj?._id.slice(5, 7)),
			subscriber: obj.totalCount,
		};
	});

	const ab = (monthData) => {
		let months = monthData?.map((obj) => obj.month);
		const array = new Array(13).fill(0);
		let finalArr;
		const newArr = array?.map((obj, i) => {
			const monthIndex = months?.indexOf(i);
			if (monthIndex > -1) {
				obj = monthData[monthIndex].subscriber;
			}
			return obj;
		});
		const first = newArr.shift();

		let semester = 1;
		if (semester == sem) {
			return (finalArr = newArr.splice(0, 6));
		} else {
			return (finalArr = newArr.splice(6, 12));
		}
	};
	// console.log("monthData", ab(monthData));
	// console.log("unSubscriber", ab(unSubscriber));
	// console.log("totalCount", ab(totalCount));
	let newArr = ab(totalCount);
	const highestVal = Math.max(...newArr);
	// console.log("highestVal", highestVal);

	newArr = newArr.map((obj, i) => {
		return highestVal - obj;
	});
	const options = {
		plugins: {
			title: {
				display: false,
			},
			legend: {
				display: false,
			},
		},
		responsive: true,
		scales: {
			x: {
				stacked: true,
				grid: {
					display: false,
				},
			},
			y: {
				stacked: true,
				beginAtZero: true,
				max: highestVal + 10,
				min: 0,
				ticks: {
					stepSize: 10,
				},
				grid: {
					display: false,
				},
			},
		},
	};
	const data = {
		labels,
		datasets: [
			{
				label: "Assinante",
				data: ab(monthData),
				backgroundColor: "#554886",
				borderRadius: {
					topLeft: 50,
					topRight: 50,
					bottomLeft: 50,
					bottomRight: 50,
				},
				barThickness: 18,
				borderWidth: 2,
				borderSkipped: false, // To make all side rounded
			},
			{
				label: "Cancelador de assinatura",
				data: ab(unSubscriber),
				backgroundColor: "#F26D81",
				borderRadius: {
					topLeft: 50,
					topRight: 50,
					bottomLeft: 50,
					bottomRight: 50,
				},
				barThickness: 18,
				borderWidth: 2,
				borderSkipped: false,
			},
			{
				label: "Total",
				data: newArr,
				backgroundColor: "rgba(227, 227, 227, 0.5)",
				borderRadius: {
					topLeft: 50,
					topRight: 50,
					bottomLeft: 50,
					bottomRight: 50,
				},
				barThickness: 18,
				borderWidth: 2,
				borderSkipped: false,
			},
		],
	};

	return <Bar options={options} data={data} />;
}
