import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

export const HomeLayout = () => {
	const { user } = useAuth();
	const outlet = useOutlet();

	if (user) {
		return <Navigate to='/home' replace />;
	}

	return (
		<div
			style={{
				backgroundColor: "#E5E5E5",
				height: "100vh",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}>
			{outlet}
		</div>
	);
};
