import {
	GET_CATEGORY_LIST,
	CATEGORY_PROCESSING,
	STOP_CATEGORY_PROCESSING,
} from "./type";

const defaultReducer = {
	categoryList: [],
	processing: false,
};

const categoryListReducer = (state = defaultReducer, action) => {
	switch (action.type) {
		case GET_CATEGORY_LIST:
			return {
				...state,
				processing: false,
				categoryList: action.payload,
			};

		case CATEGORY_PROCESSING:
			return {
				...state,
				processing: true,
			};

		case STOP_CATEGORY_PROCESSING:
			return {
				...state,
				processing: false,
			};

		default:
			return state;
	}
};

export default categoryListReducer;
