import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TableComponent from "./TableComponent";
import { experimentalStyled as styled } from "@mui/material/styles";

const Img = styled("img")({
	display: "block",
});

const TableTemplate = () => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectVal, setSelectVal] = React.useState("clientes");
	const open = Boolean(anchorEl);
	const clientsRows = [
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PENDING"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PENDING"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
		createData(
			"Jonathan Domingos",
			"Crédito",
			"R$49.99",
			"email.12434@gmail.com",
			"Mensal",
			"PADO"
		),
	];

	const [tableRows, setTableRows] = React.useState(clientsRows);

	const handleClick = (event) => {
		// console.log("event.currentTarget", event.currentTarget);
		setAnchorEl(event.currentTarget);
	};
	// React.useEffect(() => {
	// 	if (selectVal === "advogados") setTableRows(advogadosRows);
	// }, [selectVal]);
	// console.log("selectVal", selectVal);
	function createData(name, forma, valor, email, tipo, status) {
		return {
			name,
			forma,
			valor,
			email,
			tipo,
			status,
		};
	}

	return (
		<div>
			<Container
				id='basic-button'
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup='true'
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				sx={{
					paddingLeft: "unset !important",
					cursor: "pointer",
					width: 282,
					marginLeft: "unset !important",
					position: "relative",
				}}>
				{/* <Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}>
					<Typography sx={{ color: "#464A53", fontWeight: "600" }} variant='h6'>
						Ranking Advogados
					</Typography>
					<KeyboardArrowDownIcon />
				</Box> */}
			</Container>

			<TableComponent rows={tableRows} />
		</div>
	);
};

export default TableTemplate;
