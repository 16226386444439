import React from "react";
import { Button } from "@mui/material";
import TableComponent from "./TableComponent";
import { experimentalStyled as styled } from "@mui/material/styles";
import AddNoteModal from "./AddNoteModal";
import { getNoteType } from "../../helper/API/note";
import Loader from "../Loader";
import RecordFound from "../RecordFound/RecordFound";
import { connect } from "react-redux";
import * as noteAction from "../../redux/noteList/action";
import SearchInput from "../SearchInput/index";
const Img = styled("img")({
	display: "block",
});

const TableTemplate = ({ noteListFromRedux, processing, noteListData }) => {
	const [tableRows, setTableRows] = React.useState(noteListFromRedux);
	const [loading, setLoading] = React.useState(false);
	const [refresh, setRefresh] = React.useState(1);
	const [addNoteTypes, setAddNoteTypes] = React.useState(false);
	const [searchVal, setSearchVal] = React.useState("");

	React.useEffect(() => {
		noteListData();
	}, [refresh]);

	React.useEffect(() => {
		setTableRows(noteListFromRedux);
	}, [noteListFromRedux]);

	console.log("noteListFromRedux", noteListFromRedux);
	// console.log("processing", processing);

	const handleAddNote = (row) => {
		setAddNoteTypes(true);
	};

	// SEARCH IN TABLE
	const onSearchNote = () => {
		setSearchVal("");
		setTableRows(noteListFromRedux);
	};

	const onChangeSearch = (event) => {
		setSearchVal(event.target.value);
		if (event.target.value) {
			var updateState = tableRows.filter(({ label }) =>
				label.toLowerCase().includes(event.target.value.toLowerCase())
			);
			setTableRows(updateState);
			updateState = [];
			// console.log("obj if 1");
		} else if (event.target.value == "") {
			setTableRows(noteListFromRedux);
			// console.log("obj if 2");
		} else {
			setTableRows([]);
			// console.log("obj if 3");
		}
	};

	return (
		<div>
			<SearchInput
				onChange={onChangeSearch}
				searchVal={searchVal}
				onClick={onSearchNote}
				placeholder='Nota de pesquisa'
			/>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					variant='contained'
					sx={{ marginBottom: 3 }}
					onClick={handleAddNote}>
					Adicionar tipo de nota
				</Button>
			</div>

			{loading ? (
				<Loader />
			) : tableRows.length ? (
				<TableComponent
					rows={tableRows}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			) : (
				<RecordFound label='Nenhum registro foi encontrado' />
			)}

			{addNoteTypes && (
				<AddNoteModal
					open={addNoteTypes}
					handleClose={() => setAddNoteTypes(false)}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			)}
		</div>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		noteListData: () => dispatch(noteAction.noteList()),
	};
};
const mapStateToProp = (state) => {
	// console.log("state", state);
	return {
		noteListFromRedux: state?.noteListListReducer?.noteList,
		processing: state?.noteListListReducer?.processing,
	};
};

export default connect(mapStateToProp, mapDispatchToProp)(TableTemplate);
