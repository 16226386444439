import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
// import { useTranslation } from "react-i18next";
import StyledButton from "../components/StyledButton";
import styled from "@emotion/styled";
import { forgotPassword } from "../helper/API/auth";
import { toast } from "react-toastify";
const Img = styled("img")({
	// margin: 'auto',
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
	position: "relative",
	left: "-10px",
	top: "-20px",
});

const ForgotPassword = () => {
	// const { login } = useAuth();
	// const { t } = useTranslation();

	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		const submitData = { email: data.get("email") };
		localStorage.setItem("email", JSON.stringify(data.get("email")));
		// console.log("submitData", submitData);
		// navigate("/verify-password");
		forgotPassword(submitData).then((res) => {
			// console.log("res", res);
			if (res.success) {
				toast.success(res.message);
				navigate("/verify-password");
			} else {
				toast.error(res.message);
			}
		});
	};

	return (
		<>
			<Container
				component='main'
				maxWidth='xs'
				sx={{ mt: 5, py: 5, px: 5, backgroundColor: "#fff" }}>
				<Link to='/'>
					<Img
						alt='complex'
						src='/assets/images/backbtn.png'
						sx={{ height: 150 }}
					/>
				</Link>
				<Container>
					<Box
						sx={{
							marginTop: 3,
							display: "flex",
							flexDirection: "column",
							alignItems: "center",
						}}>
						<Typography component='h2' variant='h5' sx={{ mb: 3 }}>
							Esqueceu sua senha
						</Typography>
						<Typography
							component='h6'
							variant='h5'
							sx={{
								color: "#92929D",
								fontSize: "16px",
								textAlign: "center",
								mb: 3,
							}}>
							Digite o endereço de e-mail que você usou quando
							ingressou para redefinir sua senha.
						</Typography>
						<Box
							component='form'
							onSubmit={handleSubmit}
							noValidate
							sx={{ mt: 1 }}>
							<TextField
								margin='normal'
								placeholder='Digite seu email'
								required
								fullWidth
								id='email'
								label='Endereço de email'
								name='email'
								autoComplete='email'
								autoFocus
							/>
							<StyledButton
								type={"submit"}
								fullWidth
								variant={"contained"}
								btnText={"Recuperar senha"}
								sx={{ mt: 3, mb: 2 }}
							/>
						</Box>
					</Box>
				</Container>
			</Container>
		</>
	);
};

export default ForgotPassword;
