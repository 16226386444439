import { AfterAuthApi } from "..";
import {
  GET_SUBSCRIBERS,
  GET_SUBSCRIBER_OFFICE,
  GET_SUBSCRIBER_DETAIL,
  GET_CLIENTS,
} from "../url";

export const getSubscribers = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_SUBSCRIBERS, "post")
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getSubscriberOffice = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_SUBSCRIBER_OFFICE, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getSubscriberDetail = (submitData) => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_SUBSCRIBER_DETAIL, "post", submitData)
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getClients = () => {
  return new Promise((resolve, reject) => {
    AfterAuthApi(GET_CLIENTS, "post")
      .then((res) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};
