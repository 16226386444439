import { getOccupationArea } from "../../helper/API/occupation";
import {
	GET_OCCUPATION_LIST,
	OCCUPATION_PROCESSING,
	STOP_OCCUPATION_PROCESSING,
} from "./type";

export const setOccupationList = (payload) => {
	// console.log("payload ser", payload);
	return {
		type: GET_OCCUPATION_LIST,
		payload,
	};
};

export const occupationList = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		// console.log("payload", payload);
		dispatch({ type: OCCUPATION_PROCESSING });
		getOccupationArea()
			.then((occupation) => {
				// console.log("occupation", occupation);
				dispatch({
					type: GET_OCCUPATION_LIST,
					payload: occupation.data,
				});
				resolve(occupation.data);
			})
			.catch((error) => {
				dispatch({ type: STOP_OCCUPATION_PROCESSING });
				// console.log("error in login action", error.message);
			});
	});
};
