import {
  GET_ADMIN_LIST,
  ADMIN_PROCESSING,
  STOP_ADMIN_PROCESSING,
} from "./type";

const defaultReducer = {
  adminList: [],
  processing: false,
};

const adminListReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_ADMIN_LIST:
      return {
        ...state,
        processing: false,

        adminList: action.payload,
      };

    case ADMIN_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case STOP_ADMIN_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default adminListReducer;
