import * as React from "react";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { FormLabel, Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { experimentalStyled as styled } from "@mui/material/styles";
import * as categoryAction from "../../redux/categoryList/action";
import { connect } from "react-redux";
import {
	addTaskCategory,
	editTaskCategoryColor,
} from "../../helper/API/category";

const EditCategoryModal = ({
	open,
	onHide,
	setRefresh,
	refresh,
	categoryColor,
	data,
	categoryListData,
}) => {
	const [values, setValues] = React.useState({
		label: data.label,
		color: data.color,
	});
	// console.log("data", data);
	const handleFormChange = (e) => {
		// console.log("event target", e.target.value);
		setValues({
			...values,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = () => {
		// console.log("values", values);
		const submtiData = {
			id: data.id,

			...values,
		};
		editTaskCategoryColor(submtiData).then((res) => {
			// console.log("res", res);
			if (res.success) {
				categoryListData();
				toast.success(res.message);
				onHide();
			} else {
				toast.error(res.message);
			}
		});
	};

	return (
		<div>
			<Dialog fullWidth maxWidth={"sm"} open={open} onClose={onHide}>
				<DialogTitle>Editar categoria</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item sm={12} md={12} lg={12}>
							<TextField
								autoFocus
								margin='dense'
								id='name'
								name='label'
								label='Etiqueta'
								type='text'
								fullWidth
								defaultValue={values.label}
								variant='standard'
								onChange={handleFormChange}
							/>
						</Grid>
						<Grid item sm={12} md={12} lg={12}>
							<FormControl variant='standard' fullWidth>
								<InputLabel id='demo-simple-select-standard-label'>
									Categoria
								</InputLabel>
								<Select
									labelId='demo-simple-select-standard-label'
									id='demo-simple-select-standard'
									defaultValue={values.color}
									name='color'
									onChange={handleFormChange}
									label='Categoria'>
									{categoryColor?.map((obj, i) => (
										<MenuItem value={obj.label}>
											<Typography
												sx={{
													height: "20px",
													width: "160px",
													borderRadius: "",
													mx: 1,
													backgroundColor: obj.label,
												}}></Typography>
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={onHide} variant='outlined'>
						Fechar
					</Button>
					<Button onClick={handleSubmit} variant='contained'>
						Enviar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
const mapDispatchToProp = (dispatch) => {
	return {
		categoryListData: () => dispatch(categoryAction.categoryList()),
	};
};
export default connect(null, mapDispatchToProp)(EditCategoryModal);
