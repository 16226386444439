import { getSubscribers } from "../../helper/API/subscribers";
import {
	GET_SUBSCRIBER_LIST,
	SUBSCRIBER_PROCESSING,
	STOP_SUBSCRIBER_PROCESSING,
} from "./type";
// import { LOGIN_USER } from "../../helper/url";

export const setSubscriberList = (payload) => {
	console.log("payload ser", payload);
	return {
		type: GET_SUBSCRIBER_LIST,
		payload,
	};
};

export const subscriberList = (payload) => (dispatch) => {
	return new Promise((resolve, reject) => {
		// console.log("payload", payload);
		dispatch({ type: SUBSCRIBER_PROCESSING });
		getSubscribers()
			.then((subscriber) => {
				// console.log("subscriber", subscriber);
				dispatch({
					type: GET_SUBSCRIBER_LIST,
					payload: subscriber.data.getSubscriberList,
				});
				resolve(subscriber.data.getSubscriberList);
			})
			.catch((error) => {
				dispatch({ type: STOP_SUBSCRIBER_PROCESSING });
				// console.log("error in login action", error.message);
			});
	});
};
