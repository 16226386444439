import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";
import { Grid, Typography } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { getSubscriberDetail } from "../../helper/API/subscribers";

const ViewSubscriberDetail = ({ open, handleClose, subscriberData }) => {
	// console.log("subscriberData", subscriberData);
	const [subscriber, setSubscriber] = useState();
	useEffect(() => {
		const submitData = { advocateId: subscriberData.id };
		getSubscriberDetail(submitData).then((res) => {
			// console.log("res view subscriber", res);
			if (res.success) {
				setSubscriber(res.data);
			} else {
				toast.error(res.message);
				handleClose();
			}
		});
	}, []);

	return (
		<div>
			<Dialog fullWidth maxWidth={"lg"} open={open} onClose={handleClose}>
				<DialogTitle>Detalhe do escritório</DialogTitle>
				<DialogContent>
					<Grid container>
						<Grid item sm={4} md={4} lg={4} mt={2}>
							<Typography sx={{ fontWeight: 900 }}>
								Nome :{" "}
								<span style={{ fontWeight: 500 }}>
									{subscriber?.name}
								</span>
							</Typography>
						</Grid>
						<Grid item sm={4} md={4} lg={4} mt={2}>
							<Typography sx={{ fontWeight: 900 }}>
								o email :{" "}
								<span style={{ fontWeight: 500 }}>
									{subscriber?.email}
								</span>
							</Typography>
						</Grid>
						<Grid item sm={4} md={4} lg={4} mt={2}>
							<Typography sx={{ fontWeight: 900 }}>
								Número de celular :{" "}
								<span style={{ fontWeight: 500 }}>
									{subscriber?.mobileNumber}
								</span>
							</Typography>
						</Grid>
						<Grid item sm={4} md={4} lg={4} mt={2}>
							<Typography sx={{ fontWeight: 900 }}>
								Telefone :{" "}
								<span style={{ fontWeight: 500 }}>
									{subscriber?.telephone}
								</span>
							</Typography>
						</Grid>
						<Grid item sm={4} md={4} lg={4} mt={2}>
							<Typography sx={{ fontWeight: 900 }}>
								é dono :{" "}
								<span style={{ fontWeight: 500 }}>
									{subscriber?.isOwner ? "Yes" : "No"}
								</span>
							</Typography>
						</Grid>
						<Grid item sm={4} md={4} lg={4} mt={2}>
							<Typography sx={{ fontWeight: 900 }}>
								oabCode :{" "}
								<span style={{ fontWeight: 500 }}>
									{subscriber?.oabCode}
								</span>
							</Typography>
						</Grid>
						<Grid item sm={4} md={4} lg={4} mt={2}>
							<Typography sx={{ fontWeight: 900 }}>
								ufCode :{" "}
								<span style={{ fontWeight: 500 }}>
									{subscriber?.ufCode}
								</span>
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Perto</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
export default ViewSubscriberDetail;
