import {
  GET_OCCUPATION_LIST,
  OCCUPATION_PROCESSING,
  STOP_OCCUPATION_PROCESSING,
} from "./type";

const defaultReducer = {
  occupationList: [],
  processing: false,
};

const occupationListListReducer = (state = defaultReducer, action) => {
  switch (action.type) {
    case GET_OCCUPATION_LIST:
      return {
        ...state,
        processing: false,

        occupationList: action.payload,
      };

    case OCCUPATION_PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case STOP_OCCUPATION_PROCESSING:
      return {
        ...state,
        processing: false,
      };

    default:
      return state;
  }
};

export default occupationListListReducer;
