import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid, Typography } from "@mui/material";
// import { experimentalStyled as styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import EstatísticasCard from "../components/EstatísticasCard";
import ChartReact from "../components/ChartReact";
import PieChartReact from "../components/PieChartReact";
import ProgressCard from "../components/Overview/ProgressCard";
import moment from "moment/moment";
import {
	getChartData,
	getMonthChartData,
	getMonthChartDataCard,
} from "../helper/API/dashboard";
import RecordFound from "../components/RecordFound/RecordFound";

const Img = styled("img")({
	display: "block",
	maxWidth: "20px",
	maxHeight: "20px",
	marginRight: 8,
});
const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 6,
		marginTop: theme.spacing(1),
		minWidth: 180,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 18,
				color: theme.palette.text.secondary,
				marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

const useStyles = makeStyles({
	root: {
		flexGrow: 1,
		borderRadius: 160,
		marginBottom: "3rem",
		// maxWidth: 500,
		// background: "red",
		"& .MuiTab-textColorSecondary.Mui-selected": {
			color: "#ffff",
			zIndex: 60,
		},
		"& .MuiTabs-indicator": {
			height: "100% !important",
			borderRadius: 160,
			//   bottom: "4px",
			//   left: "6px !important",
			background:
				"linear-gradient(180deg, #FE2D66 0%, #A03E88 61.46%, #734699 91.15%) !important",
		},
		"& .MuiTab-wrapper": {
			textTransform: "capitalize",
			fontSize: 16,
			fontWeight: "bold",
		},
		"& .MuiLinearProgress-root": {
			height: "100px",
		},
		"& .MuiPaper-elevation1": {
			// boxShadow: "unset !important",
		},
	},
	mainCard: {
		boxShadow:
			"13.0734px 31.3761px 70.5963px rgba(0, 0, 0, 0.06) !important",
		borderRadius: 11,
	},
	actionContainer: {
		background: "#D6D9FF",
		borderTopRightRadius: "13px",
		borderBottomRightRadius: "13px",
		// padding: "8px 15px",
	},
	title: {
		fontFamily: "Poppins",
		fontStyle: "normal",
		fontWeight: "800",
		fontSize: "20px",
		// lineHeight: "26px",
	},
	subtitle: {
		color: "#757575",
		fontFamily: "Poppins",
		fontWeight: 400,
		fontSize: 18,
		// margin: "0 !important",
	},
	card: {
		// marginTop: "2rem",
		// marginBottom: "1rem",
		boxShadow: "unset !important",
		borderRadius: 11,
	},
	opinion: {
		fontFamily: "Roboto",
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "20px",
		lineHeight: "22px",
		// color: "red"
	},
	card2: {
		maxWidth: "20vw",
		height: "50vh",
		background: "url(/assets/images/card2.png) center center",
		backgroundPosition: "contain",
		backgroundRepeat: "no-repeat",
	},
	noShadow: {
		boxShadow: "unset !important",
	},
	mainCardContent: {
		padding: "30px !important",
	},
	pieContainer: {
		padding: "20px 35px !important",
	},
});

const VisãoGeral = () => {
	const classes = useStyles();

	const [sem, setSem] = React.useState(1);
	const [year, setYear] = React.useState(moment().year());
	const [chartData, setChartData] = React.useState(null);
	const [monthChartData, setMonthChartData] = React.useState(null);
	const [cardData, setCardData] = React.useState(null);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const [yearValue, setYearValue] = React.useState("yearly");
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
		// console.log("event.currentTarget", event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	// console.log("anchorEl :::", anchorEl);

	useEffect(() => {
		const submitData = { semester: sem, year };
		getChartData(submitData).then((res) => {
			// console.log("res chart", res);
			if (res.success) {
				setChartData(res.data);
			}
		});
	}, [sem, year]);
	useEffect(() => {
		getMonthChartData().then((res) => {
			// console.log("res chart setMonthChartData", res);
			if (res.success) {
				setMonthChartData(res.data);
			}
		});
	}, []);

	// FIRST CARD DROPDOWN
	useEffect(() => {
		const submitData = { filterStr: yearValue };
		getMonthChartDataCard(submitData).then((res) => {
			// console.log("res chart setMonthChartData cards", res);
			if (res.success) {
				setCardData(res.data);
			}
		});
	}, [yearValue]);

	const handleChangeQuater = (event) => {
		// console.log("event", event);
		setYearValue(event.target.value);
	};
	const handleChange = (event) => {
		setSem(event.target.value);
	};
	const handleChangeYear = (event) => {
		setYear(event.target.value);
	};

	// console.log("sem", sem);
	// console.log("yearValue", yearValue);
	// console.log("Current year is:", moment().year());
	// console.log("cardData", cardData);

	function numberWithCommas(x) {
		return x?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
	}
	const convertBrazilNumber = (x) => {
		return Number(x)?.toLocaleString("pt-BR", {
			style: "currency",
			currency: "brl",
		});
	};
	return (
		<div>
			<Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
				<Box sx={{ flexGrow: 1 }}>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
						}}>
						<h1>Estatísticas </h1>
						<div>
							<Button
								id='demo-customized-button'
								aria-controls={
									open ? "demo-customized-menu" : undefined
								}
								aria-haspopup='true'
								aria-expanded={open ? "true" : undefined}
								variant='contained'
								disableElevation
								// onChange={handleChangeQuater}
								onClick={handleClick}
								endIcon={<KeyboardArrowDownIcon />}>
								{yearValue === "monthly"
									? "Mensal"
									: yearValue === "Semiannual"
									? "Semestral"
									: "Anual"}
							</Button>
							<StyledMenu
								id='demo-customized-menu'
								MenuListProps={{
									"aria-labelledby": "demo-customized-button",
								}}
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								onChange={handleChangeQuater}>
								<MenuItem
									disableRipple
									value={"Mensal"}
									onClick={() => {
										setYearValue("monthly");
										handleClose();
									}}>
									Mensal
								</MenuItem>
								<Divider sx={{ my: 0.5 }} />
								<MenuItem
									disableRipple
									value={"Semestral"}
									onClick={() => {
										setYearValue("Semiannual");
										handleClose();
									}}>
									Semestral
								</MenuItem>

								<Divider sx={{ my: 0.5 }} />
								<MenuItem
									disableRipple
									value={"Anual"}
									onClick={() => {
										setYearValue("yearly");
										handleClose();
									}}>
									Anual
								</MenuItem>
							</StyledMenu>
						</div>
					</div>
					<Grid
						container
						spacing={{ xs: 2, md: 3 }}
						columns={{ xs: 2, sm: 8, md: 12 }}
						className={classes.root}>
						<EstatísticasCard
							src='/assets/images/statistic.png'
							total='Número Total de Usuários '
							value={cardData?.totalCount}
						/>
						<EstatísticasCard
							src='/assets/images/subscriber.png'
							total='Assinantes Ativos'
							value={cardData?.subscribers}
						/>
						<EstatísticasCard
							src='/assets/images/totals.png'
							total='Assinaturas Gratuitas'
							value={cardData?.unSubscribers}
						/>

						<Grid
							item
							xs={2}
							sm={4}
							md={3}
							className={classes.cardContent}>
							<Card
								variant='elevation'
								// className={classes.card}

								sx={{
									backgroundColor: "#fff",
									color: "#000000",
									borderRadius: "18px",
								}}>
								<CardContent
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										boxShadow:
											"0px 1px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
									}}>
									<Box>
										<Box
											display={"flex"}
											justifyContent={"space-between"}>
											<h3 className={classes.title}>
												{/* R$8.998,20 */}{" "}
												{convertBrazilNumber(
													cardData?.totalInvoice
												)}
											</h3>
											<Box>
												<Box>
													<Img
														alt='complex'
														src={
															"/assets/images/calender_white.svg"
														}
													/>
												</Box>
											</Box>
										</Box>
										<Box
											display={"flex"}
											justifyContent={"space-between"}
											alignItems={"center"}>
											<Box>
												<Box
													display={"flex"}
													justifyContent={
														"space-between"
													}
													alignItems={"center"}>
													<p className={classes.time}>
														Faturamento
													</p>
												</Box>
											</Box>
										</Box>
									</Box>
								</CardContent>
							</Card>
						</Grid>
					</Grid>
				</Box>

				<Grid
					container
					spacing={{ xs: 2, md: 3 }}
					className={classes.root}>
					<Grid item xs={12} sm={12} md={12}>
						<Card variant='elevation' className={classes.mainCard}>
							<CardContent className={classes.mainCardContent}>
								<div
									style={{
										display: "flex",
										justifyContent: "space-between",
									}}>
									<Box pl={2}>
										<Typography
											variant='h5'
											sx={{
												fontWeight: 700,
												color: "#110345",
											}}>
											Visão geral
										</Typography>
										<Typography
											variant='body1'
											sx={{
												marginTop: 2,
												color: "#727272",
												fontSize: "18px",
											}}>
											Total de usuários no aplicativo
										</Typography>
									</Box>
									<Box>
										<FormControl fullWidth>
											<Select
												labelId='demo-simple-select-label'
												id='demo-simple-select'
												defaultValue={sem}
												onChange={handleChange}
												sx={{ width: "100px" }}>
												<MenuItem value={1}>
													1º Sem
												</MenuItem>
												<MenuItem value={2}>
													2º Sem
												</MenuItem>
											</Select>
										</FormControl>
									</Box>
									<Box>
										<FormControl fullWidth>
											<Select
												labelId='demo-simple-select-label'
												id='demo-simple-select'
												defaultValue={year}
												onChange={handleChangeYear}
												sx={{ width: "100px" }}>
												<MenuItem
													value={new Date().getFullYear()}>
													{new Date().getFullYear()}
												</MenuItem>
												{/* <MenuItem value={2023}>Twenty</MenuItem> */}
											</Select>
										</FormControl>
									</Box>
								</div>
								<Grid container alignItems='center'>
									<Grid item md={6}>
										<Card className={classes.noShadow}>
											<CardContent
												className={
													classes.barContainer
												}>
												<Box>
													{chartData ? (
														<ChartReact
															chartData={
																chartData
															}
															sem={sem}
														/>
													) : (
														<RecordFound label='Nenhum registro foi encontrado' />
													)}
												</Box>
											</CardContent>
										</Card>
									</Grid>
									<Grid item md={6}>
										<Card>
											<CardContent
												className={
													classes.pieContainer
												}>
												<Box>
													<Typography
														variant='h6'
														sx={{
															fontWeight: 700,
															color: "#110345",
														}}>
														Total de assinaturas
													</Typography>
													<Typography
														variant='body1'
														sx={{
															marginTop: "9px",
															color: "#727272",
														}}>
														{monthChartData?.growth}
													</Typography>
												</Box>
												<div
													style={{
														display: "flex",
														justifyContent:
															"space-between",
														alignItems: "center",
													}}>
													<div>
														<div
															style={{
																marginTop: 18,
																display: "flex",
																justifyContent:
																	"space-between",
															}}>
															<Box>
																<Typography
																	variant='h6'
																	sx={{
																		color: "#554886",
																		fontWeight: 500,
																	}}>
																	{/* {chartData?.currentMonthData?.subscribers} */}
																	{
																		monthChartData?.subscribers
																	}
																</Typography>
																<div
																	style={{
																		display:
																			"flex",
																		justifyContent:
																			"space-between",
																		alignItems:
																			"center",
																	}}>
																	<Img src='/assets/images/EllipseBlue.png' />
																	<p>
																		Total de
																		Assinantes
																	</p>
																</div>
															</Box>
														</div>
														<div
															style={{
																display: "flex",
																justifyContent:
																	"space-between",
															}}>
															<Box>
																<Typography
																	variant='h6'
																	sx={{
																		color: "#FF6D4D",
																		fontWeight: 500,
																	}}>
																	{/* {chartData?.currentMonthData?.unSubscribers} */}
																	{
																		monthChartData?.unSubscribers
																	}
																</Typography>
																<div
																	style={{
																		display:
																			"flex",
																		justifyContent:
																			"space-between",
																		alignItems:
																			"center",
																	}}>
																	<Img src='/assets/images/EllipseOrange.png' />
																	<p>
																		Total de
																		cancelamento
																		de
																		assinatura
																	</p>
																</div>
															</Box>
														</div>
														<div
															style={{
																display: "flex",
																justifyContent:
																	"space-between",
															}}>
															<Box>
																<Typography
																	variant='h6'
																	sx={{
																		color: "#EA4C89",
																		fontWeight: 500,
																	}}>
																	{/* {chartData?.currentMonthData?.totalCount} */}
																	{
																		monthChartData?.totalCount
																	}
																</Typography>
																<div
																	style={{
																		display:
																			"flex",
																		justifyContent:
																			"space-between",
																		alignItems:
																			"center",
																	}}>
																	<Img src='/assets/images/EllipsePink.png' />
																	<p>
																		Total
																		assinantes
																	</p>
																</div>
															</Box>
														</div>
													</div>
													<div>
														<p>
															{/* {monthChartData && ( */}
															<PieChartReact
																chartData={
																	monthChartData
																}
															/>
															{/* )} */}
														</p>
													</div>
												</div>
											</CardContent>
										</Card>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				{monthChartData ? (
					<ProgressCard chartData={monthChartData} />
				) : (
					<RecordFound label='Nenhum registro foi encontrado' />
				)}
			</Box>
		</div>
	);
};
export default VisãoGeral;
