import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import {
	Box,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Typography,
	Paper,
	Checkbox,
	IconButton,
	Tooltip,
	FormControlLabel,
	Switch,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@mui/material";
import { InsertDriveFileOutlined, Done } from "@mui/icons-material";
import ViewOfficeDetail from "./ViewOfficeDetail";
import ViewSubscriberDetail from "./ViewSubscriberDetail";
import Loader from "../Loader";
import RecordFound from "../RecordFound/RecordFound";

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = () => {
	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...{
					bgcolor: "#fff",
				},
			}}>
			<Typography
				sx={{ flex: "1 1 100%", fontWeight: "bold" }}
				variant='h6'
				id='tableTitle'
				component='div'>
				Advogado
			</Typography>
		</Toolbar>
	);
};

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
};

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

const headCells = [
	{
		id: "noma",
		numeric: false,
		disablePadding: false,
		label: "Nome",
	},
	{
		id: "email",
		numeric: false,
		disablePadding: false,
		label: "o email",
	},
	{
		id: "mobileNumber",
		numeric: false,
		disablePadding: false,
		label: "Número de celular",
	},
	{
		id: "action",
		numeric: false,
		disablePadding: false,
		label: "ação",
	},
];
const useStyles = makeStyles({
	tableBody: {
		"& > :not(:last-child)": {
			borderBottom: "25px solid red",
		},
	},
	root: {
		"& .MuiTable-root, .MuiTableBody-root, .MuiPaper-root": {
			backgroundColor: "transparent !important",
			boxShadow: "unset !important",
		},
		"& .MuiTableRow-root": {
			backgroundColor: "#ffff !important",
			//   marginBottom: "10px !important",
			// borderBottom: "5px solid #F8F5FA",
		},
		"& .MuiTableCell-root MuiTableCell-head": {
			fontSize: "21px",
			fontFamily: "Poppins",
			color: "#464A53 !important",
		},
		"& .MuiTableCell-root MuiTableCell-body": {
			fontSize: "16px",
			fontFamily: "Poppins",
		},
	},
});
function EnhancedTableHead(props) {
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) => (
					<TableCell
						key={headCell.id}
						align={"left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						// className={classes.tableHead}
						sx={{
							fontSize: "15px",
							fontWeight: "bold",
							color: "#809FB8 !important",
						}}
						// sortDirection={orderBy === headCell.id ? order : false}>
						sortIcon='hide'
						sortDirection={false}>
						<TableSortLabel
							//   active={orderBy === headCell.id}
							IconComponent={false}
							direction={orderBy === headCell.id ? order : "asc"}
							//   onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === "desc"
										? "sorted descending"
										: "sorted ascending"}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
};

export default function EnhancedTable({ rows }) {
	// console.log("default API data", rows);

	const classes = useStyles();
	const [order, setOrder] = React.useState("asc");
	const [openViewDetail, setOpenViewDetail] = React.useState(false);
	const [officeAdvocate, setOfficeAdvocate] = React.useState();
	const [openSubscriber, setOpenSubscriber] = React.useState(false);
	const [subscriberData, setSubscriberData] = React.useState();
	const [orderBy, setOrderBy] = React.useState("data");
	const [selected, setSelected] = React.useState([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;
	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
	const totalPage = Math.ceil(rows.length / rowsPerPage);
	// console.log("rows :: Subscribers", rows);
	const mapPage = Array.from(Array(totalPage).keys());

	const handleViewOffice = (row) => {
		setOfficeAdvocate(row);
		setOpenViewDetail(true);
	};
	const handleViewSubscriber = (row) => {
		setSubscriberData(row);
		setOpenSubscriber(true);
	};
	return (
		<Box sx={{ width: "100%" }} className={classes.root}>
			<Paper sx={{ width: "100%", mb: 2 }}>
				<EnhancedTableToolbar />
				<TableContainer>
					<Table
						sx={{ minWidth: 750 }}
						aria-labelledby='tableTitle'
						size={dense ? "small" : "medium"}>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody py={2}>
							{stableSort(rows, getComparator(order, orderBy))
								.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								)
								.map((row, index) => (
									<TableRow
										hover
										style={{
											marginBottom: "10px !important",
										}}
										tabIndex={-1}
										key={index}>
										<TableCell
											align='left'
											style={{
												color: "#3D4655",
												fontWeight: "bold",
												fontSize: "16px",
											}}>
											{row.name}
										</TableCell>
										<TableCell
											align='left'
											style={{
												color: "#3D4655",
												fontWeight: "bold",
												fontSize: "16px",
											}}>
											{row.email}
										</TableCell>{" "}
										<TableCell
											align='left'
											style={{
												color: "#3D4655",
												fontWeight: "bold",
												fontSize: "16px",
											}}>
											{row.telephone}
										</TableCell>{" "}
										<TableCell
											align='left'
											style={{
												color: "#7F63F4",
												fontSize: "16px",
											}}
											colSpan={2}>
											<Button
												variant='outlined'
												color='inherit'
												sx={{ marginRight: 2 }}
												onClick={() =>
													handleViewSubscriber(row)
												}>
												{" "}
												Ver detalhes
											</Button>
											{row.isOffice && (
												<Button
													variant='outlined'
													color='inherit'
													sx={{ marginRight: 2 }}
													onClick={() =>
														handleViewOffice(row)
													}>
													{" "}
													ver escritório
												</Button>
											)}
										</TableCell>
									</TableRow>
								))}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				<>
					<TablePagination
						rowsPerPageOptions={[5, 10, 25]}
						labelRowsPerPage={"Mostrar até:"}
						component='div'
						count={rows.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>

					{/* <Box display="flex" justifyContent="flex-end" alignItems="center">
            {mapPage.map((obj, i) => {
              const activePageColor = i === page ? "#7F63F4" : "#fff";
              const activePageFontColor = i === page ? "#fff" : "#ABAFB3";
              return (
                <Box
                  sx={{
                    textAlign: "right",
                    background: activePageColor,
                    padding: "14px",
                    marginRight: "4px",
                    borderRadius: "50%",
                    fontWeight: 700,
                    color: activePageFontColor,
                  }}
                >
                  {" "}
                  {i < 9 ? "0" : ""}
                  {i + 1}
                </Box>
              );
            })}
          </Box> */}
				</>
			</Paper>
			{openViewDetail && (
				<ViewOfficeDetail
					open={openViewDetail}
					setOpenViewDetail={setOpenViewDetail}
					officeAdvocate={officeAdvocate}
					handleClose={() => setOpenViewDetail(false)}
				/>
			)}
			{openSubscriber && (
				<ViewSubscriberDetail
					open={openSubscriber}
					subscriberData={subscriberData}
					handleClose={() => setOpenSubscriber(false)}
				/>
			)}
		</Box>
	);
}
