import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Link as RouterLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
// import { useTranslation } from "react-i18next";
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";
import StyledButton from "../components/StyledButton";
import styled from "@emotion/styled";

import VisibilityOff from "@mui/icons-material/VisibilityOff";

const Img = styled("img")({
	// margin: 'auto',
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
});

export const LoginPage = () => {
	const { login } = useAuth();
	// const { t } = useTranslation();
	const [showPassword, setShowPassword] = React.useState(false);
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);

		const submitData = {
			email: data.get("email"),
			password: data.get("password"),
		};
		// console.log("submitData", submitData);
		login(submitData);
	};

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Container
			component='main'
			maxWidth='xs'
			sx={{ mt: 5, backgroundColor: "#fff" }}>
			<Box
				sx={{
					// marginTop: 8,
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
				}}>
				<Img
					alt='complex'
					src='/assets/images/inova_juris_logo.png'
					sx={{ height: 150 }}
				/>
				<Box
					component='form'
					onSubmit={handleSubmit}
					noValidate
					sx={{ mt: 1 }}>
					<TextField
						margin='normal'
						placeholder='Digite seu email '
						required
						fullWidth
						id='email'
						label='Endereço de email'
						name='email'
						autoComplete='email'
						autoFocus
					/>
					<FormControl sx={{ mt: 1 }} variant='outlined' fullWidth>
						<InputLabel htmlFor='password'>Senha</InputLabel>
						<OutlinedInput
							placeholder='Digite sua senha'
							id='password'
							required
							fullWidth
							name='password'
							type={showPassword ? "text" : "password"}
							endAdornment={
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={handleClickShowPassword}
										edge='end'>
										{showPassword ? (
											<VisibilityOff />
										) : (
											<Visibility />
										)}
									</IconButton>
								</InputAdornment>
							}
							label='Senha'
						/>
					</FormControl>
					<Grid
						container
						sx={{
							display: "flex",
							justifyContent: "end",
							marginBottom: "0.5rem",
						}}>
						<Grid item>
							<RouterLink to='/forgot-password'>
								<Link
									variant='body2'
									sx={{
										color: "#9A9AB0",
										textDecoration: "none",
									}}>
									Esqueceu sua senha?
								</Link>
							</RouterLink>
						</Grid>
					</Grid>
					<StyledButton
						type={"submit"}
						fullWidth
						variant={"contained"}
						btnText={"Entrar"}
						sx={{ mt: 3, mb: 2, backgroundColor: "#734699" }}
					/>
					{/* <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: "#734699" }}>
            {t("login")}
          </Button> */}

					{/* <Container
						sx={{
							color: "#9A9AB0",
							fontSize: "12px",
							display: "flex",
							justifyContent: "center",
						}}>
						Ou
					</Container>
					<StyledButton
						type={"submit"}
						fullWidth
						variant={"outlined"}
						btnText={"Faça login no Google"}
						sx={{
							mt: 2,
							mb: 5,
							color: "#5541D7",
							fontWeight: "bold",
						}}
					/> */}
					{/* <Button type="submit" fullWidth variant="outlined" sx={{ mt: 2, mb: 5, color: "#5541D7", fontWeight: "bold" }}>
            {t("signInGoogle")}
          </Button> */}
					{/* <Grid container>
            <Grid item>
              <RouterLink to="/register">
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </RouterLink>
            </Grid>
          </Grid> */}
				</Box>
			</Box>
		</Container>
	);
};
