import React, { useEffect, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import styled from "@emotion/styled";
import { Container } from "@mui/system";
import { Divider, Grid, Paper, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { getNotification } from "../helper/API/notification";
import RecordFound from "./RecordFound/RecordFound";

const NotificationMenu = ({
	open,
	setAnchorElNotification,
	anchorEl,
	setRefresh,
	refresh,
}) => {
	const StyledMenu = styled((props) => (
		<Menu
			elevation={0}
			// anchorOrigin={{
			//     vertical: 'bottom',
			//     horizontal: 'right',
			// }}
			// transformOrigin={{
			//     vertical: 'top',
			//     horizontal: 'right',
			// }}
			{...props}
		/>
	))(({ theme }) => ({
		"& .MuiPaper-root": {
			borderRadius: 6,
			marginTop: theme.spacing(1),
			minWidth: 200,

			boxShadow:
				"0px 0px 0px 0px rgba(0,0,0,0),0px 0px 0px 0px rgba(0,0,0,0),0px 3px 14px 2px rgba(0,0,0,0.15)",
			"& .MuiMenu-list": {
				padding: "4px 0",
			},
			"& .MuiMenu-list": {
				// backgroundColor: "black",
				// color: "#fff"
			},
			"& .MuiMenuItem-root": {
				"& .MuiSvgIcon-root": {
					fontSize: 18,
					color: theme.palette.text.secondary,
					marginRight: theme.spacing(1.5),
				},
			},
		},
	}));

	const [notifications, setNotifications] = useState([]);
	const [reload, setReload] = useState();

	useEffect(() => {
		getNotification().then((res) => {
			// console.log("res notification received", res);
			if (res.success) {
				setNotifications(res.data);
			} else {
				setNotifications([]);
			}
		});
	}, [reload]);

	const handleCloseMenu = () => {
		setRefresh(refresh + 1);
		setReload(reload + 1);
		setAnchorElNotification(null);
	};

	return (
		<div>
			<StyledMenu
				id='notification-button'
				anchorEl={anchorEl}
				open={open}
				onClose={handleCloseMenu}
				MenuListProps={{
					"aria-labelledby": "notification-button",
				}}>
				<Container>
					<CardContent>
						<Grid container>
							{/* <Grid item md={1} >
                            </Grid> */}
							<Grid
								item
								md={9}
								sx={{
									marginBottom: "3rem",
									marginTop: "1rem",
								}}>
								<Typography
									variant='h5'
									sx={{
										fontWeight: "600",
										color: "#222B45",
									}}>
									{" "}
									Notificações
								</Typography>
							</Grid>
							<Grid item md={2} sx={{ marginTop: "1rem" }}>
								<div
								// style={{
								// 	padding: "0.3rem 0.3rem",
								// 	boxShadow:
								// 		"0px 12.3427px 18.5141px rgba(0, 0, 0, 0.1)",
								// 	display: "flex",
								// 	justifyContent: "center",
								// 	borderRadius: "50%",
								// 	height: "30%",
								// }}
								>
									{/* <img
										src='/assets/images/icon.png'
										height={15}
									/> */}
								</div>
							</Grid>
							<Grid item md={2}></Grid>
							<Grid
								item
								md={5}
								sx={{
									color: "#222222",
									fontSize: "18px",
									fontWeight: 600,
								}}>
								novas mensagens
							</Grid>
							<Grid
								item
								md={5}
								sx={{
									color: "#AAAAAA",
									fontSize: "14px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}>
								AGORA MESMO
							</Grid>
						</Grid>
						{notifications.length ? (
							notifications.map((obj, index) => (
								<Grid container>
									<Grid item md={12}>
										<Grid container spacing={2}>
											<Grid item md={2}>
												<Grid
													container
													sx={{
														padding: "1rem 1rem",
													}}>
													<Grid
														item
														md={12}
														sx={{
															display: "flex",
															justifyContent:
																"center",
														}}>
														<Box
															sx={{
																padding: "1rem",
																borderRadius:
																	"50%",
																background:
																	"linear-gradient(175.65deg, #FE2D66 3.99%, #734699 97.46%)",
																boxShadow:
																	"0px 9.95139px 14.9271px rgba(8, 42, 69, 0.15)",
																display: "flex",
																justifyContent:
																	"center",
															}}>
															<img src='/assets/images/reviewsicon.png' />
														</Box>
													</Grid>
												</Grid>

												<Grid
													item
													md={12}
													sx={{
														height: "60%",
														display: "flex",
														justifyContent:
															"center",
													}}>
													<Box
														sx={{
															display: "flex",
															justifyContent:
																"center",
															alignItems:
																"center",
															height: "85%",
															bgcolor: "#DDDDDD",
														}}>
														<Divider
															orientation='vertical'
															flexItem
															sx={{
																border: "1px solid #DDDDDD",
																width: "2px",
																borderRadius:
																	"2.98542px",
															}}
														/>
													</Box>
												</Grid>
											</Grid>
											<Grid item md={9}>
												<Card sx={{ minWidth: 275 }}>
													<CardContent>
														<Typography
															sx={{
																mb: 1.5,
																color: "#222222",
																fontSize:
																	"18px",
																fontWeight: 600,
															}}>
															{obj.title}
														</Typography>
														<hr width={"100%"} />
														<Typography
															variant='body2'
															sx={{
																color: "#222222",
																fontSize:
																	"18px",
																fontWeight: 600,
															}}>
															{obj.subTitle}
														</Typography>
														<Typography
															variant='body2'
															sx={{
																width: "275px",
															}}>
															{obj.message}
														</Typography>
													</CardContent>
												</Card>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							))
						) : (
							<RecordFound label='Nenhuma notificação encontrada' />
						)}
						{/* <Grid container>
              <Grid item md={12}>
                <Grid container spacing={2}>
                  <Grid item md={2} sx={{ border: "2px solid #fff" }}>
                    <Grid container sx={{ padding: "1rem 1rem" }}>
                      <Grid
                        item
                        md={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            padding: "1rem",
                            borderRadius: "50%",
                            background:
                              "linear-gradient(175.65deg, #FE2D66 3.99%, #734699 97.46%)",
                            boxShadow:
                              "0px 9.95139px 14.9271px rgba(8, 42, 69, 0.15)",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img src="/assets/images/reviewsicon.png" />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={12}
                      sx={{
                        height: "60%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "85%",
                          bgcolor: "#DDDDDD",
                        }}
                      >
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            border: "1px solid #DDDDDD",
                            width: "2px",
                            borderRadius: "2.98542px",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item md={9}>
                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        <Typography
                          sx={{
                            mb: 1.5,
                            color: "#222222",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          Erica Mendes
                        </Typography>
                        <hr width={"100%"} />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#222222",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          Message
                        </Typography>
                        <Typography variant="body2" sx={{ width: "275px" }}>
                          I can do it Dr. Fernando, I'll attach it here, thank
                          you!
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={12}>
                <Grid container spacing={2}>
                  <Grid item md={2} sx={{ border: "2px solid #fff" }}>
                    <Grid container sx={{ padding: "1rem 1rem" }}>
                      <Grid
                        item
                        md={12}
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        <Box
                          sx={{
                            padding: "1rem",
                            borderRadius: "50%",
                            background:
                              "linear-gradient(175.65deg, #FE2D66 3.99%, #734699 97.46%)",
                            boxShadow:
                              "0px 9.95139px 14.9271px rgba(8, 42, 69, 0.15)",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img src="/assets/images/reviewsicon.png" />
                        </Box>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      md={12}
                      sx={{
                        height: "60%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "85%",
                          bgcolor: "#DDDDDD",
                        }}
                      >
                        <Divider
                          orientation="vertical"
                          flexItem
                          sx={{
                            border: "1px solid #DDDDDD",
                            width: "2px",
                            borderRadius: "2.98542px",
                          }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item md={9}>
                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        <Typography
                          sx={{
                            mb: 1.5,
                            color: "#222222",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          Process
                        </Typography>
                        <hr width={"100%"} />
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#222222",
                            fontSize: "18px",
                            fontWeight: 600,
                          }}
                        >
                          Message
                        </Typography>
                        <Typography variant="body2" sx={{ width: "275px" }}>
                          New update in process 2135656952
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid> */}
					</CardContent>
				</Container>
				{/* </Container> */}
			</StyledMenu>
		</div>
	);
};
export default NotificationMenu;
