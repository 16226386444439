import React from "react";
import { Box, Container, Typography } from "@mui/material";
import TableComponent from "./TableComponent";
import { experimentalStyled as styled } from "@mui/material/styles";

const Img = styled("img")({
	display: "block",
});

const TableTemplate = ({ rows, setRefresh, refresh }) => {
	// console.log("rows", rows);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectVal, setSelectVal] = React.useState("clientes");
	const open = Boolean(anchorEl);

	const [tableRows, setTableRows] = React.useState(rows);

	const handleClick = (event) => {
		// console.log("event.currentTarget", event.currentTarget);
		setAnchorEl(event.currentTarget);
	};
	React.useEffect(() => {
		setTableRows(rows);
	}, [rows]);
	// console.log("selectVal", selectVal);
	function createData(name, forma, valor, email, tipo, status) {
		return {
			name,
			forma,
			valor,
			email,
			tipo,
			status,
		};
	}

	return (
		<div>
			<Container
				id='basic-button'
				aria-controls={open ? "basic-menu" : undefined}
				aria-haspopup='true'
				aria-expanded={open ? "true" : undefined}
				onClick={handleClick}
				sx={{
					paddingLeft: "unset !important",
					cursor: "pointer",
					width: 282,
					marginLeft: "unset !important",
					position: "relative",
				}}></Container>

			<TableComponent
				rows={tableRows}
				setRefresh={setRefresh}
				refresh={refresh}
			/>
		</div>
	);
};

export default TableTemplate;
