import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import CustomizedSearchBar from "../components/Inputs/SearchBar";
import TableTemplate from "../components/Client/TableTemplate";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { getClients } from "../helper/API/subscribers";
import RecordFound from "../components/RecordFound/RecordFound";
import { connect } from "react-redux";
import * as clientAction from "../redux/clientList/action";
import SearchInput from "../components/SearchInput";

const Img = styled("img")({
	// margin: 'auto',
	display: "block",
	maxWidth: "100%",
	maxHeight: "100%",
});

const Client = ({ clientListData, clientListFromRedux, processing }) => {
	const [tableRows, setTableRows] = React.useState(clientListFromRedux);
	const [loading, setLoading] = React.useState(false);
	const [refresh, setRefresh] = React.useState(1);
	const [searchVal, setSearchVal] = React.useState("");
	const navigate = useNavigate();

	React.useEffect(() => {
		clientListData();
	}, [refresh]);

	React.useEffect(() => {
		setTableRows(clientListFromRedux);
	}, [clientListFromRedux]);
	// console.log("clientListFromRedux", clientListFromRedux);
	// console.log("tableRows", tableRows);

	// SEARCH IN TABLE
	const onSearchOffice = () => {
		setSearchVal("");
		setTableRows(clientListFromRedux);
	};

	const onChangeSearch = (event) => {
		setSearchVal(event.target.value);
		if (event.target.value) {
			var updateState = tableRows.filter(({ name }) =>
				name.toLowerCase().includes(event.target.value.toLowerCase())
			);
			setTableRows(updateState);
			// console.log("obj if 1", updateState);
			updateState = [];
		} else if (event.target.value == "") {
			setTableRows(clientListFromRedux);
			// console.log("obj if 2");
		} else {
			setTableRows([]);
			// console.log("obj if 3");
		}
	};
	return (
		<Box sx={{ flexGrow: 1, background: "#F8F5FA" }} px={8} py={4}>
			<SearchInput
				onChange={onChangeSearch}
				searchVal={searchVal}
				onClick={onSearchOffice}
				placeholder='Procurar cliente'
			/>

			{processing ? (
				<Loader />
			) : tableRows?.length ? (
				<TableTemplate
					rows={tableRows}
					setRefresh={setRefresh}
					refresh={refresh}
				/>
			) : (
				<RecordFound label='Nenhum registro foi encontrado' />
			)}
		</Box>
	);
};

const mapDispatchToProp = (dispatch) => {
	return {
		clientListData: () => dispatch(clientAction.clientList()),
	};
};
const mapStateToProp = (state) => {
	// console.log("state", state);
	return {
		clientListFromRedux: state?.clientListReducer?.clientList,
		processing: state?.clientListReducer?.processing,
	};
};
export default connect(mapStateToProp, mapDispatchToProp)(Client);
