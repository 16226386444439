import { AfterAuthApi } from "..";
import {
	GET_CATEGORY,
	ADD_CATEGORY,
	GET_CATEGORY_COLOR,
	DELETE_CATEGORY,
	EDIT_CATEGORY,
} from "../url";

export const getTaskCategory = () => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(GET_CATEGORY, "post")
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const addTaskCategory = (values) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(ADD_CATEGORY, "post", values)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const getTaskCategoryColor = () => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(GET_CATEGORY_COLOR, "get")
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const deleteTaskCategoryColor = (data) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(DELETE_CATEGORY, "post", data)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};

export const editTaskCategoryColor = (data) => {
	return new Promise((resolve, reject) => {
		AfterAuthApi(EDIT_CATEGORY, "post", data)
			.then((res) => {
				resolve(res.data);
			})
			.catch(reject);
	});
};
